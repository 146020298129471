import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReceptorForm from "components/ReceptorForm";
import Page from "components/Page";
import Loading from "components/Loading";
import useAxios from "hooks/useAxios";
import useAppContext from "hooks/useAppContext";
import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { ReactComponent as ReceptorCreadoIcon } from 'assets/receptor-creado.svg';

export default function Receptor() {
  const axios = useAxios();
  const history = useHistory();
  const { setFlashMessage } = useAppContext();
  const { distribuidorId, receptorId } = useParams();
  const [receptor, setReceptor] = useState(null);
  const [destinatarios, setDestinatarios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCancel = () => {
    history.push(`/distribuidores/${distribuidorId}/receptores`);
  }

  const handleCreate = async (body, errorHandler) => {
    try {
      const response = await axios.post(
        `/comercial/distribuidores/${distribuidorId}/receptores`,
        body,
      );
      setFlashMessage({
        icon: ReceptorCreadoIcon,
        text: `Se ha creado el receptor "${response.data.nombre} ${response.data.apellido}"`,
      });

      handleCancel();
    } catch (error) {
      errorHandler(error.response.data?.errors ?? {});
    }
  };

  const handleUpdate = async (body, errorHandler) => {
    try {
      const response = await axios.put(
        `/comercial/distribuidores/${distribuidorId}/receptores/${receptorId}`,
        body,
      );

      setFlashMessage({
        icon: ReceptorCreadoIcon,
        text: `Se ha modificado el receptor "${response.data.nombre} ${response.data.apellido}"`,
      });

      handleCancel();
    } catch (error) {
      errorHandler(error.response.data?.errors ?? {});
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/comercial/distribuidores/${distribuidorId}/receptores/${receptorId}`);

      setFlashMessage({
        icon: TrashIcon,
        text: `Se ha eliminado el receptor "${response.data.nombre} ${response.data.apellido}"`,
      });

      handleCancel();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReceptor = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`/comercial/distribuidores/${distribuidorId}/receptores/${receptorId}`);
      setReceptor(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const fetchDestinatarios = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`/comercial/distribuidores/${distribuidorId}/destinatarios`);

      setDestinatarios(response.data);

      if (receptorId) {
        await fetchReceptor();
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchDestinatarios();
  }, []);

  return (
    <Page backUrl={`/distribuidores/${distribuidorId}/receptores`}>
      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <Page.Header backUrl={`/distribuidores/${distribuidorId}/receptores`}>
            <Page.Title>
              {receptor
                ? `Modificar receptor: ${receptor.nombre} ${receptor.apellido}`
                : 'Crear nuevo receptor'
              }
            </Page.Title>
          </Page.Header>

          <ReceptorForm
            receptor={receptor}
            destinatarios={destinatarios}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onCancel={handleCancel}
          />
        </>
      )}
    </Page>
  )
}
