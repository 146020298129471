import TransportesItem from './TransportesItem';
import sinPedidos from 'assets/sin-pedidos.svg';

export default function Transportes({ transportes, selectedOptions }) {
  return (
    <div className="flex flex-col w-full">
      <div className="mb-2 p-2 bg-gray-100 flex items-center rounded">
        <div className="p-2" style={{ width: '30px' }} />
        <div className="p-2 font-bold text-center" style={{ width: '200px' }}>
          TRANSPORTE N°
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '200px' }}>
          ESTADO TRANSPORTE
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '350px' }}>
          TRANSPORTISTA
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '300px' }}>
          Nº TELEFONO TRANSPORTISTA
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '300px' }}>
          FECHA DE DESPACHO
        </div>
        <div
          className="p-2 font-bold text-center"
          style={{ width: '300px', paddingLeft: '20px' }}
        ></div>
      </div>
      {transportes.length === 0 && (
        <div className="flex flex-col justify-center items-center bg-white p-8 rounded">
          <img src={sinPedidos} alt="sin pedidos" />
          <div className="font-bold mt-4">
            No hay resultados para la búsqueda ingresada, pruebe limpiando los
            filtros.
          </div>
        </div>
      )}
      {transportes.map((transporte) => (
        <TransportesItem
          transporte={transporte}
          key={transporte.id}
          selectedOptions={selectedOptions}
        />
      ))}
    </div>
  );
};
