import { estados } from 'utils';

export default function HeaderEntrega({ entrega }) {
  return (
    <div
      className="flex justify-between px-8 py-4 -mx-8 mb-3 font-bold text-white"
      style={{ background: estados[entrega.estado].color }}
    >
      <div>Estado: {estados[entrega.estado].nombre}</div>
      <div>N° Entrega {entrega.codigo}</div>
      <div>N° Transporte {entrega.transporte.codigo}</div>
    </div>
  );
}
