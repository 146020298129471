import { useEffect } from 'react';
import axios from '../api/axios';
import config from '../config';

export default function useAxios() {
  const token = window.localStorage.getItem('token');

  useEffect(() => {
    const requestIntercept = axios.interceptors.request.use(
      (config) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseIntercept = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          window.location.replace(config.ssoUrl);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestIntercept);
      axios.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return axios;
}
