import config from '../config';
import logoNidera from 'assets/logo-nidera.png';
import logoSPS from 'assets/logo-sps.png';
import logoNK from 'assets/logo-nk.svg';

export default function LogoMarca() {
  if (config.marca === 'nk') {
    return <img src={logoNK} alt="NK" style={{ height: '25px' }} />;
  }

  if (config.marca === 'sps') {
    return <img src={logoSPS} alt="SPS" style={{ height: '25px' }} />;
  }

  return <img src={logoNidera} alt="Nidera" style={{ height: '30px' }} />;
}
