import { ReactComponent as CloseIcon } from 'assets/close.svg';

export default ({ filters, selectedOptions, onClean }) => {
  const items = [];

  Object.keys(filters).forEach((key) => {
    const filter = filters[key];
    const selected = selectedOptions[key];

    if (selected && selected.length > 0) {
      const firstOptionKey = selected[0];
      const firstSelectedOption = filter.options.find(
        (option) => option.key === firstOptionKey,
      );

      if (firstSelectedOption) {
        items.push({
          title: filter.title,
          description:
            selected.length > 1
              ? selected.length
              : firstSelectedOption.value,
        });
      }
    }
  });

  if (items.length === 0) {
    return <></>;
  }

  return (
    <div className="flex mb-3">
      <button
        type="button"
        className="hidden lg:flex gap-2 items-center mr-4"
        style={{ color: '#ff6060' }}
        onClick={onClean}
      >
        <CloseIcon width="24" />
        Limpiar filtros
      </button>

      {items.map((item) => (
        <div key={item.key} className="ec__filter-summary flex items-center py-2 px-4 rounded mr-2">
          <strong className="mr-1">{item.title}:</strong>
          {item.description}
        </div>
      ))}
    </div>
  );
};
