import { useLocation, Link } from 'react-router-dom';
import Page from 'components/Distribuidor/Mobile/Page';
import entregaIcon from 'assets/entrega.svg';
import { estados } from 'utils';

export default function Entregas() {
  const location = useLocation();
  const cliente = location.state.cliente;

  return (
    <Page title="Envíos">
      <div className="text-gray-800 font-semibold text-xl">
        {`Transporte ${cliente.transporte.codigo} - ${cliente.nombre}`}
      </div>
      {cliente &&
        cliente.entregas.map((entrega) => (
          <div key={entrega.id} className="flex flex-col mb-3 w-full">
            <div
              className="flex flex-wrap p-2 rounded-t-lg justify-between font-bold w-full"
              style={{
                color: 'white',
                backgroundColor: estados[entrega.estado].color,
              }}
            >
              <div>{estados[entrega.estado].nombre}</div>
              <div>{`Nº Transporte ${cliente.transporte.codigo}`}</div>
            </div>
            <div key={entrega.codigo} className="p-4 bg-white">
              <div className="flex items-center">
                <div className="mr-4">
                  <img src={entregaIcon} alt="Entrega" />
                </div>
                <div>
                  <p className="mt-2 text-black-600 font-semibold">
                    {entrega.destinatario.nombre}
                  </p>
                  <div className="text-black-400">
                    <strong>Nº de Pedido:</strong> {entrega.numero_pedido}
                  </div>
                  <div>
                    {`${entrega.destinatario.direccion}, ${entrega.destinatario.localidad.nombre}`}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex p-2 bg-white border-t border-b justify-between">
              <div className="mt-2 text-black-600">
                <strong>Nº de Entrega:</strong> {entrega.codigo}
              </div>
              <div className="mt-2 text-black-600">
                <strong>Nº de Remito:</strong> {entrega.numero_remito}
              </div>
            </div>

            <Link
              to={`/entregas/${entrega.id}`}
              className="ec__text--primary p-2 bg-white rounded-b-lg text-center font-bold"
            >
              Ver detalle de entrega
            </Link>
          </div>
        ))}
    </Page>
  );
};
