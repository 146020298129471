import styled from 'styled-components';
import dayjs from 'dayjs';

const StyledMessage = styled.div`
  letter-spacing: 0.3px;
  padding: 21px;
  max-width: 334px;
  font-weight: normal;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  border-radius: 40px 40px 50px 50px;

  &::before {
    color: #fff;
  }

  border-bottom-right-radius: ${(props) => (props.fromMe ? '0' : '50')};
  border-bottom-left-radius: ${(props) => (props.fromMe ? '50' : '0')};
  background-color: ${(props) => (props.fromMe ? '#ddfebc' : '#fff')};
  align-items: ${(props) => (props.fromMe ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.fromMe ? 'right' : 'left')};
  margin-left: ${(props) => (props.fromMe ? 'auto' : '')};
`;

const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 5px;
`;

const StyledText = styled.p`
  font-size: 16px;
  line-height: 20px;
`;

const StyledDate = styled.p`
  font-size: 12px;
  line-height: 16px;
  padding: 8px 3px;
  width: 100%;
  text-align: right !important;
`;

export default function Mensaje({ data }) {
  const hoy = dayjs().format('DD/MM/YY');
  let hora = dayjs(data.creado_el).format('HH:mm');
  let dia = dayjs(data.creado_el).format('DD/MM/YY');

  if (dia === hoy) {
    dia = '';
  } else {
    hora = '';
  }

  return (
    <StyledMessage fromMe={data.usuario && data.usuario.rol === 'distribuidor'}>
      <StyledTitle>
        {data.usuario ? data.usuario.email : data.email}
      </StyledTitle>
      <StyledText>{data.texto}</StyledText>
      <StyledDate>{`${hora} ${dia}`}</StyledDate>
    </StyledMessage>
  );
};
