import { Switch, Route } from 'react-router-dom';
import DetalleEntrega from './pages/Distribuidor/Desktop/DetalleEntrega';
import Notificaciones from './pages/Distribuidor/Desktop/Notificaciones';
import Mensajes from './pages/Distribuidor/Desktop/Mensajes';
import Receptores from './pages/Distribuidor/Desktop/Receptores';
import Home from './pages/Distribuidor/Desktop/Home';
import Receptor from './pages/Distribuidor/Desktop/Receptor';
import PoliticaPrivacidad from './pages/PoliticaPrivacidad';
import NotFound from 'components/NotFound';
import Perfil from './pages/Distribuidor/Desktop/Perfil';

export default function RoutesDistribuidor() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/receptores" component={Receptores} />
      <Route path="/receptores-nuevo" component={Receptor} />
      <Route path="/receptores-editar/:receptorId" component={Receptor} />
      <Route path="/entregas/:entregaId" component={DetalleEntrega} />
      <Route path="/entregas-mensajes/:entregaId" component={Mensajes} />
      <Route path="/notificaciones" component={Notificaciones} />
      <Route path="/privacidad" component={PoliticaPrivacidad} />
      <Route path="/perfil" component={Perfil} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
