import NotificacionEstado from "components/NotificacionEstado";

export default function RowNotificacion({ notificacion, onClick }) {
  return (
    <div
      onClick={() => onClick && onClick(notificacion)}
      className="cursor-pointer gap-2 py-4 px-4 lg:px-8 w-full flex flex-col shadow-[0_2px_5px_rgba(0,0,0,0.1)] rounded-[10px] bg-white"
    >
      <div>
        <span className={`${notificacion.leido_el ? 'bg-gray-200 text-gray-400' : 'ec__tag--notificacion' } px-2 py-1 rounded-[8px] font-bold`}>
          {`${notificacion.distribuidor.nombre}`}
        </span>
      </div>

      <div className="flex w-full">
        <div className="mr-6">
          <NotificacionEstado tipo={notificacion.tipo} />
        </div>

        <div className="flex w-full flex-col">
          <div className="flex">
            <div className="mr-auto">
              <p className="font-bold text-[#454547]">{notificacion.asunto}</p>
              <p className="font-bold text-[#45454780] lg:hidden">
                {`Nº de transporte ${notificacion.transporte.codigo}`}
              </p>
              <p className="text-[#909191]">{notificacion.mensaje}</p>
              <small className="text-[#909191]">{notificacion.creado_hace}</small>
            </div>
            <div className="text-right font-bold hidden lg:block">
              <p className="text-[#45454780]">Nº de transporte</p>
              <p className="text-[#90919160]">
                {notificacion.transporte.codigo}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
