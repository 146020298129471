import React from 'react';
import LogoMarca from 'components/LogoMarca';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="invisible lg:visible mt-6">
      <hr className="w-full border-[#C4C4C4] my-2" />
      <footer className="flex justify-between items-center px-8 mb-4">
        <p className="opacity-60">© {new Date().getFullYear()} En Camino</p>
        <section className="flex items-center">
          <Link to="/privacidad" className="opacity-60 mr-4">Políticas de privacidad</Link>
          <LogoMarca />
        </section>
      </footer>
    </div>
  );
}
