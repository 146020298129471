import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import ModalMotivos from 'components/Distribuidor/Desktop/ModalMotivos';
import Page from 'components/Page';
import NotFound from 'components/NotFound';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import HeaderEntrega from 'components/Distribuidor/Desktop/HeaderEntrega';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';
import { ReactComponent as ClockIcon } from 'assets/clock.svg';
import { parteDelDia } from 'utils';
import config from '../../../config';

const DetalleEnvio = ({ entrega }) => {
  const localidad = entrega.destinatario.localidad?.nombre;
  const despachadoEl = dayjs(entrega.transporte.despachado_el);
  const entregaEstimadaEl = dayjs(entrega.entrega_estimada_el);

  return (
    <div className="flex gap-4">
      <div className="flex gap-2 w-1/2">
        <CheckCircleIcon />
        <div>
          <strong>
            Despacho
          </strong>
          <div>
            {`Su pedido fue correctamente despachado el ${dayjs(despachadoEl).format('DD/MM/YYYY')} a las ${despachadoEl.format('HH:mm')}`}
          </div>
        </div>
      </div>
      <div className="flex gap-2 w-1/2">
        <ClockIcon />
        <div>
          <strong>
            Entrega Estimada
          </strong>
          <div>
            {`${localidad} - ${entregaEstimadaEl.format('DD/MM/YY')} por la ${parteDelDia(entregaEstimadaEl)}`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function DetalleEntrega() {
  const axios = useAxios();
  const history = useHistory();
  const { entregaId } = useParams();
  const { entrega, setEntrega, mensajes, setMensajes } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const hangleMessageButtonClick = () => {
    if (mensajes.length > 0) {
      history.push(`/entregas-mensajes/${entregaId}`);
    } else {
      setShowModal(true);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response1 = await axios.get(`/distribuidor/entregas/${entregaId}`);
      setEntrega(response1.data);

      const response2 = await axios.get(`/distribuidor/entregas/${entregaId}/mensajes`);
      setMensajes(response2.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page>
      {isLoading && <div className="text-center">Cargando...</div>}

      {!isLoading && entrega === null && (
        <NotFound>No se encontró la entrega</NotFound>
      )}

      {!isLoading && entrega && (
        <>
          <HeaderEntrega entrega={entrega} />

          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1 p-4 bg-white shadow">
                <strong>Distribuidor:</strong>
                <div>
                  {entrega.distribuidor?.nombre || '--'}
                </div>
              </div>
              <div className="flex flex-col gap-1 p-4 bg-white shadow">
                <strong>Destinatario:</strong>
                <div>
                  {entrega.destinatario.nombre || '--'}
                </div>
              </div>
              <div className="flex flex-col gap-1 p-4 bg-white shadow">
                <strong>Cliente:</strong>
                <div>{entrega.cliente?.nombre || '--'}</div>
              </div>
              <div className="flex flex-col gap-1 p-4 bg-white shadow">
                <strong>N° de Remito:</strong>
                <div>{entrega.numero_remito || '--'}</div>
              </div>
              <div className="flex flex-col gap-1 p-4 bg-white shadow">
                <strong>N° de Pedido:</strong>
                <div>{entrega.numero_pedido || '--'}</div>
              </div>
              <div className="flex flex-col gap-1 p-4 bg-white shadow">
                <strong>Fecha de desp.:</strong>
                <div>{entrega.fecha_despacho || '--'}</div>
              </div>
            </div>
            <div className="w-full">
              <table className="bg-white rounded shadow w-full">
                <thead className="border-b-2">
                  <tr>
                    <th className="p-4 text-left">Descripción</th>
                    <th className="p-4 text-right">Cantidad</th>
                    <th className="p-4 text-left">Cod. Mat.</th>
                    <th className="p-4 text-left">Lote</th>
                    <th className="p-4 text-left">Placa</th>
                  </tr>
                </thead>
                <tbody>
                  {entrega.detalle.map((item) => (
                    <tr key={item.id} className="border-b">
                      <td className="p-4 text-left">{item.material_descripcion}</td>
                      <td className="p-4 text-right" style={{ width: '120px' }}>{item.cantidad}</td>
                      <td className="p-4 text-left" style={{ width: '200px' }}>{item.material_codigo}</td>
                      <td className="p-4 text-left" style={{ width: '200px' }}>{item.lote}</td>
                      <td className="p-4 text-left" style={{ width: '200px' }}>{item.placa}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <DetalleEnvio entrega={entrega} />
            </div>

            {config.marca === 'nk' && (
              <div className="ml-auto">
                <button
                  type="button"
                  className="ec__btn--primary py-2 px-4 rounded"
                  onClick={hangleMessageButtonClick}
                >
                  Dejar mensaje
                </button>
              </div>
            )}
          </div>

          <ModalMotivos
            entregaId={entrega.id}
            onCloseModal={() => setShowModal(false)}
            showModal={showModal}
          />
        </>
      )}
    </Page>
  );
}
