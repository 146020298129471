import { useLocation, Link } from 'react-router-dom';
import Page from 'components/Distribuidor/Mobile/Page';

export default function Transporte() {
  const location = useLocation();
  const transporte = location.state.transporte;

  // Obtengo los clientes únicos de las entregas

  const clientes = transporte.entregas
    .map((entrega) => ({
      id: entrega.cliente_id,
      nombre: entrega.cliente.nombre,
      transporte,
      entregas: [],
    }))
    .filter(
      (a, b, array) =>
        array.findIndex((cliente) => cliente.id === a.id) === b,
    );

  // Le asigno las entrega a cada cliente único

  clientes.forEach((cliente) => {
    cliente.entregas = transporte.entregas.filter(
      (entrega) => entrega.cliente_id === cliente.id,
    );
  });

  return (
    <Page title="Envíos">
      <div className="text-gray-800 font-semibold text-xl mb-2">
        {`Transporte ${transporte.codigo}`}
      </div>
      {clientes.map((cliente) => (
        <div
          key={cliente.id}
          className="flex flex-col items-start p-4 bg-white rounded-lg mb-3 w-full"
        >
          <div className="mb-3">
            <div className="mb-4 text-sm">{`${cliente.entregas.length} ENTREGAS PENDIENTES`}</div>
            <div className="mb-2 font-semibold">{cliente.nombre}</div>
            <p className="text-gray-400">
              {`${transporte.localidadDestino.nombre}, ${transporte.localidadDestino.provincia} `}
            </p>
          </div>
          <Link
            to={{
              pathname: `/entregas`,
              state: { cliente },
            }}
            className="ec__btn--primary py-2 px-4 rounded-md"
          >
            Ver más
          </Link>
        </div>
      ))}
    </Page>
  );
};
