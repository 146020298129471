import { useState } from 'react';
import FilterOptions from './FilterOptions';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';

export default function FilterAccordion({
  filterKey,
  filter,
  selectedOptions,
  setSelectedOptions,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className={`flex w-full justify-between ${
          isOpen && 'hidden'
        } border-b-2 py-5 px-2 lg:!hidden`}
      >
        <h3 className="text-2xl">{filter.title}</h3>
        <button onClick={() => setIsOpen(true)}>
          <PlusIcon className="ec__text--primary" />
        </button>
      </div>

      <div className={`${!isOpen && 'hidden'} lg:border-0 border-b-2 lg:!block`}>
        <FilterOptions
          key={filterKey}
          filterKey={filterKey}
          filter={filter}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  );
}
