import dayjs from 'dayjs';
import { ReactComponent as ClockIcon } from 'assets/clock.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg'

export default function DetalleEnvio({ data }) {
  const despachadoEl = data.transporte.despachado_el;
  const localidad = data.destinatario.localidad.nombre;
  const entregaEstimadaEl = data.entrega_estimada_el;
  const entregaEstimadaHora = dayjs(entregaEstimadaEl).format('HH');
  const entregaEstimadaFecha = dayjs(entregaEstimadaEl).format('DD/MM/YY');
  let entregaEstimadaParteDelDia = '';

  if (entregaEstimadaHora < 12) {
    entregaEstimadaParteDelDia = 'mañana';
  } else if (entregaEstimadaHora >= 12 && entregaEstimadaHora < 18) {
    entregaEstimadaParteDelDia = 'tarde';
  } else if (entregaEstimadaHora >= 18) {
    entregaEstimadaParteDelDia = 'noche';
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <div>
        <div className="text-xl font-bold">
          <CheckCircleIcon />
          Despacho
        </div>

        {`Su pedido fue correctamente despachado el ${dayjs(
          despachadoEl,
        ).format('DD/MM/YYYY')} a las ${dayjs(despachadoEl).format('HH:mm')}`}
      </div>
      <div>
        <div className="text-xl font-bold">
          <ClockIcon />
          Entrega Estimada
        </div>
        {`${localidad} - ${entregaEstimadaFecha} por la ${entregaEstimadaParteDelDia}`}
      </div>
    </div>
  );
}
