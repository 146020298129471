import { createGlobalStyle } from 'styled-components';

const PROPS = {
  nk: {
    background: '#F9FAFC',
    fontFamily: 'Open Sans',
    logoFilter: 'none',
    navbarBackground: 'linear-gradient(109deg, #01558C 15.79%, #06936E 100%)',
    navbarColor: 'white',
    activeNavbarBackground: '#24AEA0CC',
    activeNavbarColor: 'white',
    primaryBg: '#2E966D',
    secondaryBg: '#235190',
    tertiaryBg: '#2E966D',
    primaryText: '#235190',
    secondaryText: '#2E966D',
    tertiaryText: '#2E966D',
    btnPrimaryBg: '#2E966D',
    btnPrimaryColor: 'white',
    btnSecondaryBg: '#235190',
    btnSecondaryColor: 'white',
    btnTertiaryBg: '#2E966D',
    btnTertiaryColor: 'white',
    btnOutlineBorderColor: '#2E966D',
    btnOutlineColor: '#2E966D',
    invertFilter: 'none',
    filterSummaryBg: '#23519030',
    filterSummaryColor: '#235190',
    tagNotificacionBg: '#2E966D30',
    tagNotificacionColor: '#2E966D',
    tagReceptorProductorBg: '#58AA3430',
    tagReceptorProductorColor: '#2E966D',
    tagReceptorInternoBg: '#2E966D30',
    tagReceptorInternoColor: '#2E966D',
    theadBg: '#23519030',
    theadColor: '#225088',
  },
  nd: {
    background: '#F9FAFC',
    fontFamily: 'Titillium Web, sans-serif',
    logoFilter: 'invert(100%)',
    navbarBackground: '#FFD100',
    navbarColor: 'black',
    activeNavbarBackground: '#391E11',
    activeNavbarColor: 'white',
    primaryBg: '#FFD100',
    secondaryBg: '#391E11',
    tertiaryBg: '#391E11',
    primaryText: '#391E11',
    secondaryText: '#FFD100',
    tertiaryText: '#391E11',
    btnPrimaryBg: '#FFD100',
    btnPrimaryColor: 'black',
    btnSecondaryBg: '#391E11',
    btnSecondaryColor: 'white',
    btnTertiaryBg: '#391E11',
    btnTertiaryColor: 'white',
    btnOutlineBorderColor: '#391E11',
    btnOutlineColor: '#391E11',
    invertFilter: 'invert(100%)',
    filterSummaryBg: '#391E1130',
    filterSummaryColor: '#391E11',
    tagNotificacionBg: '#FFD10030',
    tagNotificacionColor: '#8F7600',
    tagReceptorProductorBg: '#391E1130',
    tagReceptorProductorColor: '#391E11',
    tagReceptorInternoBg: '#FFD10030',
    tagReceptorInternoColor: '#8F7600',
    theadBg: '#D3CECD',
    theadColor: '#391E11',
  },
  sps: {
    background: '#F9FAFC',
    fontFamily: 'Open Sans',
    logoFilter: 'none',
    navbarBackground: 'linear-gradient(109deg, #46be3c 15.79%, #026c4d 100%)',
    navbarColor: 'white',
    activeNavbarBackground: '#46be3c',
    activeNavbarColor: 'white',
    primaryBg: '#46be3c',
    secondaryBg: '#026c4d',
    tertiaryBg: '#46be3c',
    primaryText: '#026c4d',
    secondaryText: '#46be3c',
    tertiaryText: '#46be3c',
    btnPrimaryBg: '#026c4d',
    btnPrimaryColor: 'white',
    btnSecondaryBg: '#026c4d',
    btnSecondaryColor: 'white',
    btnTertiaryBg: '#46be3c',
    btnTertiaryColor: 'white',
    btnOutlineBorderColor: '#46be3c',
    btnOutlineColor: '#46be3c',
    invertFilter: 'none',
    filterSummaryBg: '#026c4d30',
    filterSummaryColor: '#026c4d',
    tagNotificacionBg: '#2E966D30',
    tagNotificacionColor: '#46be3c',
    tagReceptorProductorBg: '#58AA3430',
    tagReceptorProductorColor: '#46be3c',
    tagReceptorInternoBg: '#2E966D30',
    tagReceptorInternoColor: '#46be3c',
    theadBg: '#026c4d30',
    theadColor: '#026c4d',
  },
};

const Theme = createGlobalStyle`
  body {
    background: ${(props) => PROPS[props.brand].background};
    font-family: ${(props) => PROPS[props.brand].fontFamily};
  }
  .ec__ {
    &encamino_logo {
      height: 30px;
      filter: ${(props) => PROPS[props.brand].logoFilter};
    }
    &navbar {
      background: ${(props) => PROPS[props.brand].navbarBackground};
      color: ${(props) => PROPS[props.brand].navbarColor};

      &--active {
        background: ${(props) => PROPS[props.brand].activeNavbarBackground};
        color: ${(props) => PROPS[props.brand].activeNavbarColor};
      }
    }
    &bg {
      &--primary {
        background: ${(props) => PROPS[props.brand].primaryBg};
      }
      &--secondary {
        background: ${(props) => PROPS[props.brand].secondaryBg};
      }
      &--tertiary {
        background: ${(props) => PROPS[props.brand].tertiaryBg};
      }
    }
    &text {
      &--primary {
        color: ${(props) => PROPS[props.brand].primaryText};
      }
      &--secondary {
        color: ${(props) => PROPS[props.brand].secondaryText};
      }
      &--tertiary {
        color: ${(props) => PROPS[props.brand].tertiaryText};
      }
    }
    &btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 30px;
      border-radius: 8px;

      &--primary {
        background: ${(props) => PROPS[props.brand].btnPrimaryBg};
        color: ${(props) => PROPS[props.brand].btnPrimaryColor};
      }
      &--secondary {
        background: ${(props) => PROPS[props.brand].btnSecondaryBg};
        color: ${(props) => PROPS[props.brand].btnSecondaryColor};
      }
      &--tertiary {
        background: ${(props) => PROPS[props.brand].btnTertiaryBg};
        color: ${(props) => PROPS[props.brand].btnTertiaryColor};
      }
      &--outline {
        border-color: ${(props) => PROPS[props.brand].btnOutlineBorderColor};
        color: ${(props) => PROPS[props.brand].btnOutlineColor};
      }
    }
    &invert {
      filter: ${(props) => PROPS[props.brand].invertFilter};
    }
    &filter-summary {
      background: ${(props) => PROPS[props.brand].filterSummaryBg};
      color: ${(props) => PROPS[props.brand].filterSummaryColor};
    }
    &tag {
      &--notificacion {
        background: ${(props) => PROPS[props.brand].tagNotificacionBg};
        color: ${(props) => PROPS[props.brand].tagNotificacionColor};
      }
      &--receptor {
        padding: 5px 0;
        width: 100px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        text-transform: capitalize;
        &-productor {
          background: ${(props) => PROPS[props.brand].tagReceptorProductorBg};
          color: ${(props) => PROPS[props.brand].tagReceptorProductorColor};
        }
        &-interno {
          background: ${(props) => PROPS[props.brand].tagReceptorInternoBg};
          color: ${(props) => PROPS[props.brand].tagReceptorInternoColor};
        }
      }
    }
    &thead {
      background: ${(props) => PROPS[props.brand].theadBg};
      color: ${(props) => PROPS[props.brand].theadColor};
    }
  }
`;

export default Theme;
