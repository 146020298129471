import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import RowReceptor from "components/RowReceptor";
import Page from "components/Page";
import NotFound from "components/NotFound";
import FlashMessage from "components/Comercial/FlashMessage";
import useAxios from "hooks/useAxios";
import useAppContext from "hooks/useAppContext";
import { ReactComponent as AddressIcon } from "assets/address-book.svg";

const CreateButton = ({ distribuidorId }) => (
  <Link
    className="ec__btn ec__btn--primary"
    to={`/distribuidores/${distribuidorId}/receptores-crear`}
  >
    <AddressIcon className="mr-2" /> Crear Receptor
  </Link>
);

const LIMITE_RECEPTORES = 50;

export default function Receptores() {
  const axios = useAxios();
  const { flashMessage, setFlashMessage } = useAppContext();
  const { distribuidorId } = useParams();
  const [receptores, setReceptores] = useState([]);
  const [distribuidor, setDistribuidor] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const fetchDistribuidor = async () => {
    try {
      const response = await axios.get(`/comercial/distribuidores/${distribuidorId}`);
      setDistribuidor(response.data);
      await fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`/comercial/distribuidores/${distribuidorId}/receptores`);
      setReceptores(response.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchDistribuidor();

    if (flashMessage) {
      setTimeout(() => {
        setFlashMessage(null);
      }, 3000);
    }
  }, []);

  return (
    <Page backUrl="/">
      <Page.Header backUrl="/">
        <div className="flex gap-2 flex-col lg:flex-row lg:justify-between">
          <Page.Title>
            Receptores {distribuidor?.nombre}
          </Page.Title>

          {receptores.length >= LIMITE_RECEPTORES && (
            <div className="text-red-500">
              Alcanzó el límite de receptores ({LIMITE_RECEPTORES})
            </div>
          )}

          {receptores.length > 0 && receptores.length < LIMITE_RECEPTORES && (
            <CreateButton distribuidorId={distribuidorId} />
          )}
        </div>
      </Page.Header>

      {isLoading && <div className="text-gray-400 text-center">Cargando datos...</div>}

      {!isLoading && receptores.length === 0 && (
        <NotFound>
          <p className="mb-2">
            Aún no se han agregado receptores a este distribuidor.
          </p>
          <CreateButton distribuidorId={distribuidorId} />
        </NotFound>
      )}

      {!isLoading && receptores.map((receptor) => (
        <RowReceptor
          key={receptor.id}
          receptor={receptor}
          editPath={`/distribuidores/${distribuidorId}/receptores-editar/${receptor.id}`}
          habilitar={(body) => axios.patch(
            `/comercial/distribuidores/${distribuidorId}/receptores/${receptor.id}`,
            body,
          )}
        />
      ))}

      {flashMessage && <FlashMessage message={flashMessage} />}
    </Page>
  );
}
