export const estados = {
  pendiente: {
    nombre: 'Pendiente',
    color: '#f9ba00',
  },
  encurso: {
    nombre: 'En Curso',
    color: '#124DBF',
  },
  entregado: {
    nombre: 'Entregado',
    color: '#4c4c4c',
  },
  rechazado: {
    nombre: 'Rechazado',
    color: '#ff5722',
  },
};

export const motivos = [
  {
    key: 'order_did _not _came',
    descripcion: 'No llego el pedido',
  },
  {
    key: 'carrier_damaged_the_package',
    descripcion: 'El transportista dañó el paquete',
  },
  {
    key: 'given_to_someone_else',
    descripcion: 'Se entregó a otra persona',
  },
  {
    key: 'other',
    descripcion: 'Otra consulta',
  },
];

export const parteDelDia = (fecha) => {
  const hora = fecha.format('HH');

  if (hora < 12) {
    return 'mañana';
  }

  if (hora >= 18) {
    return 'noche';
  }

  return 'tarde';
};

export const parseTelefono = (val) => {
  let telefono = (val || '');

  telefono = telefono.substring(telefono.indexOf(' ') + 1).trim();

  if (telefono.startsWith('+')) {
    return '';
  }

  return telefono;
}
