import { Route, Switch } from 'react-router-dom';
import Home from './pages/Comercial/Home';
import Notificaciones from './pages/Comercial/Notificaciones';
import DetalleEntrega from './pages/Comercial//Entrega';
import Receptor from './pages/Comercial/Receptor';
import Receptores from './pages/Comercial/Receptores';
import NotFound from 'components/NotFound';
import PoliticaPrivacidad from 'pages/PoliticaPrivacidad';

export default function RoutesComercialKam() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/entregas/:entregaId" component={DetalleEntrega} />
      <Route path="/notificaciones" component={Notificaciones} />
      <Route path="/distribuidores/:distribuidorId/receptores" component={Receptores} />
      <Route path="/distribuidores/:distribuidorId/receptores-crear" component={Receptor} />
      <Route path="/distribuidores/:distribuidorId/receptores-editar/:receptorId" component={Receptor} />
      <Route path="/privacidad" component={PoliticaPrivacidad} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
