import { useState, useEffect } from 'react';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as LessIcon } from 'assets/minus.svg';

export default function FilterOptions({
  filterKey,
  filter,
  selectedOptions,
  setSelectedOptions,
  setIsOpen,
}) {
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const updateSelectedOptions = (options) => {
    setSelectedOptions({
      ...selectedOptions,
      [filterKey]: [...options],
    });
  };

  const handleSelectAll = () => {
    updateSelectedOptions(filter.options.map(({ key }) => key));
  };

  const handleSelectNone = () => {
    updateSelectedOptions([]);
  };

  const handleCheck = (optionKey) => {
    const optionIndex = checkedOptions.indexOf(optionKey);

    if (optionIndex === -1) {
      checkedOptions.push(optionKey);
    } else {
      checkedOptions.splice(optionIndex, 1);
    }

    updateSelectedOptions(checkedOptions);
  };

  useEffect(() => {
    if (search.length > 0) {
      setFilteredOptions(
        filter.options.filter(
          (option) => String(option.value ?? '').toLowerCase().includes(search.toLocaleLowerCase())
        )
      );
    } else {
      setFilteredOptions([...filter.options]);
    }
  }, [search, filter]);

  useEffect(() => {
    if (selectedOptions[filterKey]) {
      setCheckedOptions([...selectedOptions[filterKey]]);
    }
  }, [selectedOptions]);

  return (
    <div className="flex flex-col bg-[#F9F9F9] lg:p-8 w-full h-full">
      <div className="flex justify-between items-center my-8">
        <h3 className="font-bold text-2xl">{filter.title}</h3>
        <button className="lg:hidden" onClick={() => setIsOpen(false)}>
          <LessIcon />
        </button>
      </div>

      <div className="flex items-center justify-center bg-[#F9F9F9] border-2 p-3 rounded">
        <SearchIcon />
        <input
          className="w-full rounded outline-0 border-0 bg-[#F9F9F9]"
          type="search"
          onChange={handleSearchChange}
          name="search"
          value={search}
          placeholder={filter.placeholder}
          autoComplete="off"
        />
      </div>

      <div className="flex w-full justify-start my-2 justify-between mb-6">
        <button
          type="button"
          className="ec__text--primary focus:outline-none p-2 font-medium"
          onClick={handleSelectAll}
        >
          Seleccionar todo
        </button>
        <button
          type="button"
          className="focus:outline-none p-2 text-gray-600 font-medium"
          onClick={handleSelectNone}
        >
          Deseleccionar todo
        </button>
      </div>

      <div
        className="flex flex-col w-full overflow-y-auto px-2"
        style={{ height: '200px' }}
      >
        {filteredOptions.map((option) => (
          <label key={option.key} className="block w-full py-1">
            <input
              type="checkbox"
              className="rounded border-gray-300 mr-4 p-4 lg:p-2"
              checked={checkedOptions.includes(option.key)}
              onChange={() => handleCheck(option.key)}
            />
            {option.value}
          </label>
        ))}
      </div>
    </div>
  );
};
