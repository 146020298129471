import { useState } from 'react';
import { Link } from 'react-router-dom';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import arrowDownIcon from 'assets/arrow-down.svg';
import arrowUpIcon from 'assets/arrow-up.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as AddressBookIcon } from 'assets/address-book.svg';

const Semaphore = ({ color }) => {
  const colors = {
    1: '#27AE60',
    2: '#F8F200',
    3: '#C4C4C4',
  };

  return (
    <div
      style={{
        width: '25px',
        height: '25px',
        borderRadius: '100%',
        borderWidth: '5px',
        borderColor: colors[color],
       }}
    ></div>
  );
};

const TableDesktopRow = ({ date, transport, order, delivery, material, entregaId }) => {
  return (
    <tr
      className={`border-b border-gray-200 h-[68px] bg-white`}
    >
      <td className="py-2 px-3 text-right">{date}</td>
      <td className="py-2 px-3 text-right">{transport}</td>
      <td className="py-2 px-3 text-right">{order}</td>
      <td className="py-2 px-3 text-right">{delivery}</td>
      <td className="py-2 px-3 text-left">{material}</td>
      <td className="py-2 px-3 text-right">
        <Link
          className="ec__btn--outline inline-block font-semibold border-2 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-14 py-2.5 text-center mr-6 dark:border-green-700 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          to={`/entregas/${entregaId}`}
        >
          Ver entrega
        </Link>
      </td>
    </tr>
  );
}

const TableDesktop = ({ open, entregas }) => {
  return (
    <section className={`hidden w-full ${open ? 'lg:!block' : 'hidden'}`}>
      <table className="overflow-hidden	rounded-[8px_8px_8px_8px] w-full drop-shadow-[0_2px_5px_rgba(0,0,0,0.1)]">
        <thead className="ec__bg--secondary text-white">
          <tr className="h-[68px]">
            <th className="py-2 px-3 text-right font-normal">Fe. Desp.</th>
            <th className="py-2 px-3 text-right font-normal">Transporte</th>
            <th className="py-2 px-3 text-right font-normal">Pedido</th>
            <th className="py-2 px-3 text-right font-normal">Entrega</th>
            <th className="py-2 px-3 text-left font-normal">Desc. Mat.</th>
            <th className="py-2 px-3 text-right font-normal"></th>
          </tr>
        </thead>
        <tbody>
          {entregas.map((entrega, index) => (
            <TableDesktopRow
              key={index}
              date={entrega.entrega_fecha_despacho}
              transport={entrega.transporte_codigo}
              order={entrega.entrega_numero_pedido}
              delivery={entrega.entrega_codigo}
              material={entrega.material_descripcion}
              entregaId={entrega.entrega_id}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}

const TableMobile = ({ open, entregas }) => {
  return (
    <section className="flex flex-col gap-[10px]">
      {entregas.map((entrega, index) => (
        <section
          key={index}
          className={`${open ? 'block' : 'hidden'} lg:hidden`}
        >
          <section className="ec__bg--secondary w-full text-white p-[16px_40px_16px_24px] rounded-[8px_8px_0_0]">
            <p>Viaje {index + 1}</p>
          </section>

          <section className="bg-white p-[24px]">
            <article className="pb-8">
              <h3 className="font-semibold">Fecha de despacho</h3>
              <p>{entrega.entrega_fecha_despacho}</p>
            </article>
            <article className="pb-8">
              <h3 className="font-semibold">Transporte</h3>
              <p>{entrega.transporte_codigo}</p>
            </article>
            <article className="pb-8">
              <h3 className="font-semibold">Pedido</h3>
              <p>{entrega.entrega_numero_pedido}</p>
            </article>
            <article className="pb-8">
              <h3 className="font-semibold">Entrega</h3>
              <p>{entrega.entrega_codigo}</p>
            </article>
            <article className="pb-4">
              <h3 className="font-semibold">Descripción de material</h3>
              <p>{entrega.material_descripcion}</p>
            </article>
            <Link
              className="ec__btn--outline inline-block w-full font-semibold border-2 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-14 py-2.5 text-center mr-6 dark:border-green-700 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
              to={`/entregas/${entrega.entrega_id}`}
            >
              Ver entrega
            </Link>
          </section>
        </section>
      ))}
    </section>
  );
}

export default function RowDistribuidor({
  distribuidor,
  historico,
  selectedOptions,
}) {
  const axios = useAxios();
  const { auth } = useAppContext();
  const [open, setOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClick = () => {
    if (distribuidor.entregas.length > 0) {
      setOpen((prevState) => !prevState);
    }
  };

  const handleDownload = async (event) => {
    setIsDownloading(true);

    try {
      const response = await axios.get(
        '/comercial/distribuidores/download',
        {
          params: {
            ...selectedOptions,
            historico,
            distribuidor: distribuidor.id,
          },
          responseType: 'blob'
        },
      );

      if (response) {
        const href = window.URL.createObjectURL(response.data);
        const anchor = document.createElement('a');

        anchor.href = href;
        anchor.setAttribute(
          'download',
          response.headers['x-suggested-filename'] ?? 'Distribuidor.xlsx',
        );
        anchor.click();

        window.URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    }

    setIsDownloading(false);
  };

  return (
    <article className="flex flex-col mb-2 fadeIn">
      <section className="flex w-full bg-white items-center rounded-md py-5 px-8 shadow mb-2">
        <img
          onClick={handleClick}
          style={{ opacity: distribuidor.entregas.length === 0 && 0.5 }}
          className="cursor-pointer mr-6"
          src={open ? arrowUpIcon : arrowDownIcon }
          alt="Mas información"
        />

        <section className="flex items-center grow">
          {!historico && (
            <div className="mr-4">
              <Semaphore color={distribuidor.color} />
            </div>
          )}
          <p
            style={{
              opacity: distribuidor.entregas.length === 0 && 0.5,
            }}
          >
            {distribuidor.nombre}
          </p>
        </section>

        <section className="flex justify-end items-center lg:border-l border-[#C4C4C450] pl-4">
          <button
            className="ec__text--primary hidden lg:flex items-center justify-center py-2 px-4 mr-4"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            <DownloadIcon className="mr-2" />
            <span className="font-semibold">
              {isDownloading ? 'Descargando...' : 'Descargar XLSX'}
            </span>
          </button>

          {auth.isComercialKam && (
            <Link
              className="ec__btn--primary flex gap-2 items-center justify-center py-2 px-4 rounded-md"
              to={`/distribuidores/${distribuidor.id}/receptores`}
            >
              <AddressBookIcon />
              <span className="hidden lg:block">Ver Receptores</span>
            </Link>
          )}
        </section>
      </section>

      <TableDesktop open={open} entregas={distribuidor.entregas} />
      <TableMobile open={open} entregas={distribuidor.entregas} />
    </article>
  );
};
