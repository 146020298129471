import styled from 'styled-components';

const StyledTitle = styled.p`
  padding-top: 18px;
  padding-bottom: 8px;
  font-weight: 700;
  color: #000;
  font-size: 18px;
`;

const StyledDescription = styled.p`
  font-weight: 400;
  color: #666;
  line-height: 23px;
`;

const StyledArticleData = styled.div`
  display: flex;
  border-bottom: 1px solid #d2d2d2;
`;

const StyledData = styled.p`
  color: #666;
  font-weight: 400;
  padding-bottom: 20px;
  padding-top: 5px;
`;

const StyledDataContainer = styled.div`
  flex: 0 50%;
  color: #000;
  font-weight: 700;
  padding-top: 10px;
`;

export default function Articulo({ data }) {
  return (
    <div>
      <StyledTitle>Descripción</StyledTitle>
      <StyledDescription> {data.material_descripcion}</StyledDescription>
      <StyledArticleData>
        <StyledDataContainer>
          <p>Cantidad</p>
          <StyledData>{data.cantidad}</StyledData>
        </StyledDataContainer>
        <StyledDataContainer>
          <p>Lote</p>
          <StyledData>{data.lote}</StyledData>
        </StyledDataContainer>
        <StyledDataContainer>
          <p>Placa</p>
          <StyledData>{data.placa}</StyledData>
        </StyledDataContainer>
      </StyledArticleData>
    </div>
  );
}
