import { useEffect, useState } from 'react';
import TransportesEntregas from './TransportesEntregas';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';

const Item = ({ cliente }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={`${show ? 'ec__btn--tertiary' : 'bg-white'} mb-2 p-2 flex items-center rounded`}>
        <div style={{ width: '30px' }}>
          <button
            onClick={() => setShow(!show)}
            type="button"
            className="focus:outline-none p-2 text-center rounded-md"
          >
            {show ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </button>
        </div>
        <div className="text-center" style={{ width: '350px' }}>
          {cliente.nombre}
        </div>
        <div className="flex justify-center" style={{ width: '300px' }}>
          <div
            className="rounded-full h-10 w-10 flex items-center justify-center font-bold"
            style={{
              backgroundColor: show ? '#ffffff40' : '#9a9a9a1a',
            }}
          >
            {cliente.entregas.length}
          </div>
        </div>
      </div>
      {show && <TransportesEntregas entregas={cliente.entregas} />}
    </>
  );
};

export default function TransportesClientes({ entregas }) {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    let aux = entregas
      .map((entrega) => entrega.cliente)
      .filter(
        (cliente, index, arr) =>
          arr.findIndex((item) => item.id === cliente.id) === index,
      );

    aux.forEach((cliente) => {
      cliente.entregas = entregas.filter(
        (entrega) => entrega.cliente_id === cliente.id,
      );
    });

    setClientes(aux);
  }, [entregas])

  return (
    <div className="flex flex-col w-full">
      <div className="mb-2 p-2 bg-gray-100 flex items-center rounded">
        <div className="p-2" style={{ width: '30px' }} />
        <div className="p-2 font-bold text-center" style={{ width: '350px' }}>
          CLIENTE
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '300px' }}>
          CANTIDAD DE ENTREGAS
        </div>
      </div>
      {clientes.map((cliente) => (
        <Item key={cliente.id} cliente={cliente} />
      ))}
    </div>
  );
};
