import React from 'react';
import { Link } from 'react-router-dom';
import Footer from 'components/Comercial/Footer';
import NavBar from './NavBar';
import NavMobile from './Comercial/NavMobile';
import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back.svg';

const Page = ({ children, backUrl }) => {
  return (
    <div className={`flex flex-col h-screen`}>
      <NavBar backUrl={backUrl} />
      <main className="grow flex flex-col px-8">
        {children}
      </main>
      <NavMobile />
      <Footer />
    </div>
  );
};

Page.Header = ({ children, backUrl }) => (
  <div className={`py-6 -mx-8 px-8 border-b mb-6 bg-white`}>
    {backUrl && (
      <Link className="hidden lg:block mb-2" to={backUrl}>
        <ArrowBackIcon className="ec__text--secondary" />
      </Link>
    )}
    <div>{children}</div>
  </div>
);

Page.Title = ({ children }) => (
  <div className="flex flex-col">
    <h1 className="font-bold text-2xl text-[#454547]">
      {children}
    </h1>
    <div className="ec__bg--primary hidden lg:block w-[80px] h-[8px] rounded-[8px] mt-1"></div>
  </div>
);

export default Page;
