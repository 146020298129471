import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Articulo from 'components/Distribuidor/Mobile/Articulo';
import NotFound from 'components/NotFound';
import Page from 'components/Distribuidor/Mobile/Page';
import Loading from 'components/Loading';
import useAxios from 'hooks/useAxios';

const StyledTitle = styled.h3`
  font-size: 16px;
  letter-spacing: 0.3px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.color};
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSubtitle = styled.h4`
  font-size: 21px;
  line-height: 20px;
  justify-content: left;
  font-weight: 700;
  height: 65px;
  padding: 0 15px;
  display: flex;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 15px;
  margin-bottom: 15px;
  font-size: 19px;
`;

const estados = {
  pendiente: {
    nombre: 'Pendiente',
    color: '#f9ba00',
  },
  encurso: {
    nombre: 'En curso',
    color: '#0DB030',
  },
};

export default function Articulos() {
  const axios = useAxios();
  const { entregaId } = useParams();
  const [entrega, setEntrega] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/distribuidor/entregas/${entregaId}`);
      setEntrega(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title={`N° Transporte ${entrega ? entrega.transporte.codigo : '...'}`}>
      {isLoading && <Loading />}

      {!isLoading && !entrega && <NotFound />}

      {!isLoading && entrega && (
        <>
          <StyledTitle color={estados[entrega.estado].color}>
            {estados[entrega.estado].nombre}
          </StyledTitle>
          <div>
            <StyledSubtitle>
              N° Entrega {entrega.codigo} - Detalle Artículo
            </StyledSubtitle>

            <StyledWrapper>
              {entrega.detalle.map((item) => (
                <Articulo data={item} key={item.id} />
              ))}
            </StyledWrapper>
          </div>
        </>
      )}
    </Page>
  );
};
