import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import InputMensaje from 'components/Distribuidor/InputMensaje';
import Mensaje from 'components/Distribuidor/Mensaje';
import Page from 'components/Page';
import useAppContext from 'hooks/useAppContext';
import HeaderEntrega from 'components/Distribuidor/Desktop/HeaderEntrega';

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView({behavior: 'smooth'}));
  return <div ref={elementRef} />;
};

export default function Mensajes() {
  const location = useLocation();
  const { entrega, mensajes } = useAppContext();

  let tipo = location.state?.tipo || mensajes[0]?.tipo || 'other';

  return (
    <Page>
      <HeaderEntrega entrega={entrega} />

      <div className="grow overflow-y-auto pr-4 mb-2" style={{ height: '0' }}>
        {mensajes.map((mensaje, index) => (
          <Mensaje key={index} data={mensaje} />
        ))}
        <AlwaysScrollToBottom />
      </div>

      <InputMensaje tipo={tipo} />
    </Page>
  );
};
