import { Link, useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from 'assets/arrow-left.svg';
import { ReactComponent as BoxIcon } from 'assets/box.svg';
import NotificacionIcon from "components/NotificacionIcon";

const Header = styled.div`
  font-size: 23px;
  height: 70px;
  padding: 0 20px;
`;
const Footer = styled.div`
  font-size: 16px;
  height: 70px;
`;
const Content = styled.div`
  margin: 80px 10px;
`;

export default function Page({ children, title }) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <Header className="ec__navbar fixed top-0 left-0 right-0 flex items-center justify-between font-bold">
        {location.pathname !== '/' ? (
          <ArrowLeftIcon onClick={() => history.goBack()} />
        ) : (
          <div></div>
        )}
        <div>{title}</div>
        <Link to="/notificaciones">
          <NotificacionIcon />
        </Link>
      </Header>
      <Content>
        {children}
      </Content>
      <Footer className="bg-white fixed bottom-0 left-0 right-0 flex justify-center items-center">
        <Link to="/" className="flex flex-col items-center">
          <BoxIcon className="ec__text--secondary" />
          Envíos
        </Link>
      </Footer>
    </div>
  );
};
