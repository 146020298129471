import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as EmailIcon } from 'assets/email.svg';
import { ReactComponent as SmsIcon } from 'assets/sms.svg';
import { ReactComponent as PencilIcon } from 'assets/pencil.svg';
import { ReactComponent as DotsIcon } from 'assets/dots.svg';
import { ReactComponent as LineIcon } from 'assets/line.svg';
import { ReactComponent as EyeIcon } from 'assets/eye.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/eye-slash.svg';

const List = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 24px;
  width: 100%;
  min-height: 70px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 150px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px 15px;
  }
`;
const DisabledList = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const H3 = styled.h3`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0;
`;
const Rigth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  @media (max-width: 768px) {
    gap: 1px;
    width: 100%;
    justify-content: space-between;
  }
`;
const Span = styled.span`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #909191;
  @media (max-width: 768px) {
    display: none;
  }
`;
const CustomLink = styled(Link)``;
const Div = styled.div`
  position: absolute;
  background-color: #ffffff;
  right: 35px;
  z-index: 2;
  @media (max-width: 1024px) {
    top: -75px;
  }
`;
const Button = styled.button`
  z-index: 2;
  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    top: -70px;
  }
`;

export default function RowReceptor({
  receptor,
  editPath,
  habilitar,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const handleHabilitacion = async (habilitado) => {
    await habilitar({ habilitado });

    setIsActive(habilitado);
  };

  useEffect(() => {
    setIsActive(receptor.deshabilitado_el === null);
  }, [receptor])

  useEffect(() => {
    const onClick = () => {
      setShowMenu(false);
    };

    if (showMenu) {
      document.addEventListener('click', onClick);
    }

    return () => {
      document.removeEventListener('click', onClick);
    }
  }, [showMenu]);

  return (
    <List className="mb-4">
      <Left>
        <span className={`ec__tag--receptor ec__tag--receptor-${receptor.tipo}`}>{receptor.tipo}</span>
        <Text>
          <H3>{`${receptor.nombre} ${receptor.apellido}`}</H3>
        </Text>
      </Left>
      <Rigth>
        <Span>Notificado vía:</Span>
        <div className={`flex gap-1 bg-gray-100 py-2 px-4 rounded ${receptor.notificar_email ? 'text-gray-600 font-bold' : 'text-gray-400'}`}>
          <EmailIcon /> {`Email: ${receptor.notificar_email ? 'si' : 'no'}`}
        </div>
        <div className={`flex gap-1 bg-gray-100 py-2 px-4 rounded ${receptor.notificar_sms ? 'text-gray-600 font-bold' : 'text-gray-400'}`}>
          <SmsIcon /> {`SMS: ${receptor.notificar_sms ? 'si' : 'no'}`}
        </div>
        <LineIcon />
        <CustomLink to={editPath}>
          <PencilIcon className="ec__text--secondary" />
        </CustomLink>
        <Button onClick={() => setShowMenu(true)}>
          <DotsIcon className="ec__text--secondary" />
        </Button>
        {showMenu && (
          <Div>
            <button
              type="button"
              className="ec__text--primary flex items-center shadow-md border rounded-md p-3"
              onClick={() => handleHabilitacion(!isActive)}
            >
              {
                isActive
                  ? <><EyeSlashIcon className="mr-2" /> Deshabilitar receptor</>
                  : <><EyeIcon className="mr-2" /> Habilitar receptor</>
              }
            </button>
          </Div>
        )}
      </Rigth>
      {!isActive && <DisabledList />}
    </List>
  );
}
