import { useState, useEffect } from 'react';
import Transportes from 'components/Distribuidor/Desktop/Transportes';
import Page from 'components/Page';
import useAxios from 'hooks/useAxios';
import Filter from 'components/Filter';
import FilterSummary from 'components/FilterSummary';
import NotFound from 'components/NotFound';
import Toggle from 'components/Toggle';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';

const defaultFilters = {
  pedidos: {
    title: 'Pedido',
    placeholder: 'Buscar pedido',
    options: [],
  },
  materiales: {
    title: 'Material',
    placeholder: 'Buscar material',
    options: [],
  },
  clientes: {
    title: 'Cliente',
    placeholder: 'Buscar cliente',
    options: [],
  },
};

export default function Home() {
  const axios = useAxios();
  const [transportes, setTransportes] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [historico, setHistorico] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (event) => {
    event.preventDefault();
    setIsDownloading(true);

    try {
      const response = await axios.get(
        '/distribuidor/transportes/download',
        {
          params: {
            ...selectedOptions,
            historico,
          },
          responseType: 'blob'
        },
      );

      const href = window.URL.createObjectURL(response.data);
      const anchor = document.createElement('a');

      anchor.href = href;
      anchor.setAttribute(
        'download',
        response.headers['x-suggested-filename'] ?? 'Transportes.xlsx',
      );
      anchor.click();

      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
    }

    setIsDownloading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/distribuidor/transportes', {
        params: {
          ...selectedOptions,
          historico,
        },
      });

      setTransportes(response.data.data);

      Object.keys(defaultFilters).forEach((key) => {
        defaultFilters[key].options = response.data.filters[key];
      })

      setFilters({...defaultFilters});
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }

  const handleFilterClean = () => {
    setSelectedOptions({});
  };

  const handleFilterApply = (selected) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    fetchData();
  }, [selectedOptions, historico]);

  return (
    <Page>
      <Page.Header>
        <div className="flex justify-between">
          <Page.Title>
            Pedidos
          </Page.Title>

          <div className="flex gap-3 items-center">
            <button
              onClick={handleDownload}
              disabled={isDownloading}
              className="ec__btn ec__btn--primary flex gap-2 items-center justify-center"
            >
              <DownloadIcon />
              <span>
                {isDownloading ? 'Descargando...' : 'Descargar XLSX'}
              </span>
            </button>
            <Filter
              filters={filters}
              initialSelectedOptions={selectedOptions}
              onApply={handleFilterApply}
              onClean={handleFilterClean}
            />
            <div className="flex items-center gap-2">
              <Toggle
                id="historico"
                label="Mostrar histórico"
                size="lg"
                checked={historico}
                onChange={() => setHistorico(!historico)}
              />
            </div>
          </div>
        </div>

        {transportes.length > 0 && (
          <div className="ec__text--tertiary">
            Resultados: {transportes.length} de {transportes.length} transportes
          </div>
        )}
      </Page.Header>

      <FilterSummary
        filters={filters}
        selectedOptions={selectedOptions}
        onClean={handleFilterClean}
      />

      {isLoading && <div className="text-center">Cargando...</div>}

      {!isLoading && transportes.length === 0 && (
        <NotFound>No se encontraron resultados</NotFound>
      )}

      {!isLoading && transportes.length > 0 && (
        <Transportes
          transportes={transportes}
          selectedOptions={{
            ...selectedOptions,
            historico,
          }}
        />
      )}
    </Page>
  );
};

