import { NavLink } from 'react-router-dom';
import useAppContext from 'hooks/useAppContext';
import { ReactComponent as BoxAltIcon } from 'assets/box-alt.svg';
import { ReactComponent as BellAltIcon } from 'assets/bell-alt.svg';

export default function NavMobile() {
  const { auth } = useAppContext();

  if (auth.isComercialKam || auth.isComercial) {
    return (
      <nav className="fixed flex lg:hidden bottom-[16px] left-[16px] w-[91.8%] h-[80px] bg-white z-50 justify-center items-center rounded-[10px] shadow-[0px_0px_20px_rgba(0,0,0,0.05)] z-10">
        <NavLink
          to="/"
          className="font-semibold basis-[50%] flex items-center justify-center flex-col text-gray-400 fill-gray-400"
          activeClassName="ec__text--secondary"
          exact
        >
          <BoxAltIcon />
          <p>Entregas</p>
        </NavLink>
        {auth.isComercialKam && (
          <NavLink
            to="/notificaciones"
            className="font-semibold basis-[50%] flex items-center justify-center flex-col text-gray-400 fill-gray-400"
            activeClassName="ec__text--secondary"
            exact
          >
            <BellAltIcon />
            <p>Notificaciones</p>
          </NavLink>
        )}
      </nav>
    );
  }

  return null;
}
