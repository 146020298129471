import { useEffect, useState } from "react";
import Page from "components/Page";
import RowDistribuidor from "components/Comercial/RowDistribuidor";
import Filter from "components/Filter";
import FilterSummary from "components/FilterSummary";
import NotFound from "components/NotFound";
import useAxios from "hooks/useAxios";
import Toggle from "components/Toggle";
import { ReactComponent as DownloadIcon } from 'assets/download.svg';

const defaultFilters = {
  pedidos: {
    title: 'Pedido',
    placeholder: 'Buscar pedido',
    options: [],
  },
  entregas: {
    title: 'Entrega',
    placeholder: 'Buscar entrega',
    options: [],
  },
  materiales: {
    title: 'Material',
    placeholder: 'Buscar material',
    options: [],
  },
  distribuidores: {
    title: 'Distribuidor',
    placeholder: 'Buscar distribuidor',
    options: [],
  },
};

export default function Home() {
  const axios = useAxios();
  const [distribuidores, setDistribuidores] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [historico, setHistorico] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/comercial/distribuidores', {
        params: {
          historico,
          ...selectedOptions
        }
      });

      setDistribuidores(response.data.data);

      Object.keys(defaultFilters).forEach((key) => {
        defaultFilters[key].options = response.data.filters[key];
      })

      setFilters({...defaultFilters});
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleDownload = async (event) => {
    setIsDownloading(true);

    try {
      const response = await axios.get(
        '/comercial/distribuidores/download',
        {
          params: {
            ...selectedOptions,
            historico,
          },
          responseType: 'blob'
        },
      );

      if (response) {
        const href = window.URL.createObjectURL(response.data);
        const anchor = document.createElement('a');

        anchor.href = href;
        anchor.setAttribute(
          'download',
          response.headers['x-suggested-filename'] ?? 'Distribuidor.xlsx',
        );
        anchor.click();

        window.URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    }

    setIsDownloading(false);
  };

  const handleFilterClean = () => {
    setSelectedOptions({});
  };

  const handleFilterApply = (selected) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    fetchData();
  }, [selectedOptions, historico]);

  return (
    <Page>
      <Page.Header>
        <div className="flex gap-3 flex-col items-center lg:flex-row lg:items-center">
          <Page.Title>
            Resultados
          </Page.Title>

          <Filter
            filters={filters}
            initialSelectedOptions={selectedOptions}
            onApply={handleFilterApply}
            onClean={handleFilterClean}
          />

          <button
            className="ec__btn ec__btn--primary hidden lg:flex items-center justify-center py-2 px-4 mr-4"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            <DownloadIcon className="mr-2" />
            <span className="font-semibold">
              {isDownloading ? 'Descargando...' : 'Descargar XLSX'}
            </span>
          </button>

          <div className="flex gap-2 lg:ml-auto">
            <Toggle
              id="historico"
              label="Mostrar histórico"
              size="lg"
              checked={historico}
              onChange={() => setHistorico(!historico)}
            />
          </div>
        </div>
      </Page.Header>

      <FilterSummary
        filters={filters}
        selectedOptions={selectedOptions}
        onClean={handleFilterClean}
      />

      {isLoading && <div className="text-gray-400 text-center">Cargando datos...</div>}

      {!isLoading && distribuidores.length === 0 && (
        <NotFound>
          No tiene ningún distribuidor asociado
        </NotFound>
      )}

      {!isLoading && distribuidores.map((distribuidor) => (
        <RowDistribuidor
          key={distribuidor.id}
          distribuidor={distribuidor}
          historico={historico}
          selectedOptions={selectedOptions}
        />
      ))}
    </Page>
  );
}
