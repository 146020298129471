import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReceptorDelete from 'components/ReceptorDelete';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import Input from './Input';
import ErrorMessage from './ErrorMessage';
import Toggle from './Toggle';
import { parseTelefono } from 'utils';

const Search = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div className="relative flex items-center">
      <SearchIcon className="w-5 h-5 absolute ml-3 pointer-events-none text-gray-400"/>
      <input
        type="search"
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChange}
        value={value}
        className="w-full pr-3 pl-10 py-2 rounded border-gray-300"
      />
    </div>
  );
};

export default function ReceptorForm({
  receptor,
  destinatarios,
  onUpdate,
  onCreate,
  onDelete,
  onCancel,
}) {
  const [checkedDestinatarios, setCheckedDestinatarios] = useState([]);
  const [filteredDestinatarios, setFilteredDestinatarios] = useState([]);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      tipo: 'interno',
      pais: '+54',
    },
  });
  const watchNotificarSms = watch('notificar_sms');
  const watchNotificarEmail = watch('notificar_email');

  const errorHandler = (errors) => {
    Object.keys(errors).forEach((key) => {
      setError(key, { type: 'custom', message: errors[key] });
    });
  };

  const onSubmit = async (data) => {
    if (checkedDestinatarios.length === 0) {
      setError('destinatarios', {
        type: 'custom',
        message: 'Debe seleccionar por lo menos un destinatario',
      });
      return;
    }

    if (!data.notificar_email && !data.notificar_sms) {
      setError('notificacion', {
        type: 'custom',
        message: 'Debe seleccionar por lo menos un medio de notificación',
      });
      return;
    }

    const body = {
      nombre: data.nombre,
      apellido: data.apellido,
      email: data.notificar_email ? data.email : null,
      telefono: data.notificar_sms ? data.telefono : null,
      notificar_email: data.notificar_email,
      notificar_sms: data.notificar_sms,
      tipo: data.tipo,
      destinatarios: checkedDestinatarios,
    };

    setIsSubmitting(true);

    if (receptor) {
      await onUpdate(body, errorHandler);
    } else {
      await onCreate(body, errorHandler);
    }

    setIsSubmitting(false);
  };

  const handleDestinatarioCheck = (key) => {
    const index = checkedDestinatarios.indexOf(key);

    if (index === -1) {
      checkedDestinatarios.push(key);
    } else {
      checkedDestinatarios.splice(index, 1);
    }

    setCheckedDestinatarios([...checkedDestinatarios]);
    clearErrors();
  };

  useEffect(() => {
    if (receptor) {
      setValue('nombre', receptor.nombre.trim());
      setValue('apellido', receptor.apellido.trim());
      setValue('email', receptor.email?.trim());
      setValue('tipo', receptor.tipo);
      setValue('telefono', parseTelefono(receptor.telefono));
      setValue('notificar_email', receptor.notificar_email);
      setValue('notificar_sms', receptor.notificar_sms);
      setCheckedDestinatarios(receptor.destinatarios ?? []);
    }
  }, [receptor]);

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredDestinatarios([...destinatarios]);
    } else {
      setFilteredDestinatarios(
        destinatarios.filter((item) =>
          item.nombre.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    }
  }, [destinatarios, search]);

  useEffect(() => {
    clearErrors();
  }, [watchNotificarSms, watchNotificarEmail]);

  return (
    <>
      <form method="post" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="flex flex-col gap-4 bg-white rounded-md shadow-md p-6 mb-4">
          <div className="font-bold text-lg">Datos de receptor</div>
          <div className="flex flex-col gap-4 lg:flex-row lg:w-1/2">
            <Input
              id="nombre"
              label="Nombre"
              placeholder="Nombre"
              error={errors.nombre}
              maxLength="50"
              {...register('nombre', {
                required: {
                  value: true,
                  message: 'El nombre es requerido',
                },
              })}
            />
            <Input
              id="apellido"
              label="Apellido"
              placeholder="Apellido"
              error={errors.apellido}
              maxLength="50"
              {...register('apellido', {
                required: {
                  value: true,
                  message: 'El apellido es requerido',
                },
              })}
            />
          </div>

          <div className="font-bold text-lg">Notificar vía</div>
          <div className="flex flex-col gap-4 lg:w-1/2">
            <Toggle
              id="notificar_email"
              label="E-mail"
              {...register('notificar_email')}
            />
            <Input
              id="email"
              type="email"
              placeholder="correo@correo.com"
              error={errors.email}
              {...register('email', {
                disabled: !watchNotificarEmail,
                required: {
                  value: true,
                  message: 'El email es requerido',
                },
              })}
            />
          </div>

          <div className="flex flex-col gap-4 lg:w-1/2">
            <div className="flex gap-4 flex-col lg:flex-row">
              <Toggle
                id="notificar_sms"
                label="Mensaje de texto (SMS)"
                {...register('notificar_sms')}
              />
              <Toggle
                id="notificar_whatsapp"
                label="Whatsapp (Muy pronto)"
                disabled
              />
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <Input
                id="pais"
                label="Código país"
                width="100px"
                disabled
                {...register('pais')}
              />
              <Input
                id="telefono"
                label="Número de teléfono"
                error={errors.telefono}
                {...register('telefono', {
                  disabled: !watchNotificarSms,
                  required: {
                    value: true,
                    message: 'El número de teléfono es requerido',
                  },
                })}
              />
            </div>
            <ErrorMessage error={errors.notificacion} />
          </div>

          <div className="font-bold text-lg">Destinatarios</div>
          <div className="flex flex-col gap-2">
            <Search
              value={search}
              placeholder="Buscar destinatario por nombre..."
              onChange={(event) => setSearch(event.target.value)}
            />
            <section className="mb-6 w-full overflow-y-hidden">
              <table className="bg-white border w-full lg:min-w-min min-w-[1600px] table-auto border-collapse overflow-x-scroll">
                <thead className="ec__thead text-left">
                  <tr>
                    <th className="p-2">Nro. SAP</th>
                    <th className="p-2">Descripción</th>
                    <th className="p-2">Dirección</th>
                    <th className="p-2">Localidad</th>
                    <th className="p-2">CP</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDestinatarios.map((item, index) => (
                    <tr className="border-b" key={item.id}>
                      <td className="p-2">
                        <label className="flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={checkedDestinatarios.includes(item.id)}
                            onChange={() => handleDestinatarioCheck(item.id)}
                          />
                          {item.codigo}
                        </label>
                      </td>
                      <td className="p-2">{item.nombre}</td>
                      <td className="p-2">{item.direccion}</td>
                      <td className="p-2">{item.localidad.nombre}</td>
                      <td className="p-2">{item.localidad.codigo_postal}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ErrorMessage error={errors.destinatarios} />
            </section>
          </div>

          <div className="font-bold text-lg">Tipo de receptor</div>
          <div className="flex gap-4">
            <label className="flex gap-2 items-center">
              <input
                type="radio"
                id="fiel-producto"
                value="productor"
                name="tipo-receptor"
                {...register('tipo', {
                  required: {
                    value: true,
                    message: 'Debe seleccionar un tipo de receptor',
                  },
                })}
              />
              Productor
            </label>
            <label className="flex gap-2 items-center">
              <input
                type="radio"
                id="fiel-interno"
                value="interno"
                name="tipo-receptor"
                {...register('tipo')}
              />
              Interno
            </label>
            <ErrorMessage error={errors.tipo} />
          </div>
        </div>
        <div className="flex gap-2 flex-col lg:flex-row items-center">
          <button
            type="submit"
            className="ec__btn ec__btn--primary w-full lg:w-auto disabled:opacity-75"
            disabled={isSubmitting}
          >
            <SaveIcon className="mr-2" />{' '}
            {isSubmitting
              ? 'Guardando...'
              : receptor
              ? 'Guardar cambios'
              : 'Guardar'}
          </button>

          <button
            type="button"
            className="ec__text--primary hidden lg:block py-3 px-4 w-full lg:w-auto disabled:opacity-75"
            onClick={() => onCancel()}
            disabled={isSubmitting}
          >
            Cancelar
          </button>

          {receptor && (
            <button
              type="button"
              className="ec__btn gap-3 lg:ml-auto border border-2 border-red-500 text-rose-500 w-full lg:w-auto disabled:opacity-75"
              onClick={() => setShowModal(true)}
              disabled={isSubmitting}
            >
              <TrashIcon /> Eliminar receptor
            </button>
          )}
        </div>
      </form>
      {receptor && (
        <ReceptorDelete
          show={showModal}
          receptor={receptor}
          onDelete={onDelete}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}
