import { forwardRef } from "react";
import ErrorMessage from "./ErrorMessage";

const Input = forwardRef(
  ({
    id,
    label,
    error,
    type='text',
    width = '100%',
    ...rest
  }, forwardedRef) => {
    return (
      <div className="flex flex-col gap-1" style={{ width: width }}>
        {label && (
          <label htmlFor={id} className="text-xs">
            {label}
          </label>
        )}
        <input
          id={id}
          type={type}
          ref={forwardedRef}
          className={`w-full p-2 disabled:bg-gray-200 rounded-md ${error ? 'focus:border-red-500 focus:ring-red-500 border-red-500' : 'border-gray-300'}`}
          {...rest}
        />
        <ErrorMessage error={error} />
      </div>
    );
  }
);

export default Input;

