import useAppContext from "hooks/useAppContext";
import { useMemo } from "react";
import { ReactComponent as BellIcon } from 'assets/bell.svg';

export default function NotificacionIcon() {
  const { notificaciones } = useAppContext();

  const cantidadNotificacionesNoLeidas = useMemo(() =>
    notificaciones
      .filter((n) => n.leido_el === null)
      .length,
    [notificaciones]
  );

  return (
    <span className="relative inline-block">
      <BellIcon />
      {cantidadNotificacionesNoLeidas > 0 && (
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
          {cantidadNotificacionesNoLeidas}
        </span>
      )}
    </span>
  )
};
