import { Link } from "react-router-dom";
import EstadoEntrega from "./EstadoEntrega";

const Item = ({ entrega }) => {
  return (
    <div className="mb-2 p-2 flex items-center rounded bg-white">
      <div style={{ width: '30px' }} />
      <div className="p-2 text-center" style={{ width: '200px' }}>
        {entrega.codigo}
      </div>
      <div className="p-2 text-center" style={{ width: '200px' }}>
        {entrega.numero_pedido || '--'}
      </div>
      <div className="p-2 text-center" style={{ width: '200px' }}>
        <EstadoEntrega estado={entrega.estado} />
      </div>
      <div className="p-2 text-center" style={{ width: '350px' }}>
        {entrega.destinatario.nombre || '--'}
      </div>
      <Link
        to={`/entregas/${entrega.id}`}
        className="ec__text--tertiary p-2 text-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ver detalle
      </Link>
    </div>
  );
};

export default function TransportesEntregas({ entregas }) {
  return (
    <div className="flex flex-col w-full">
      <div className="mb-2 p-2 bg-gray-100 flex items-center rounded">
        <div style={{ width: '30px' }} />
        <div className="p-2 font-bold text-center" style={{ width: '200px' }}>
          N DE ENTREGA
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '200px' }}>
          N DE PEDIDO
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '200px' }}>
          ESTADO
        </div>
        <div className="p-2 font-bold text-center" style={{ width: '350px' }}>
          DESTINATARIO
        </div>
      </div>
      {entregas.map((entrega) => (
        <Item key={entrega.id} entrega={entrega} />
      ))}
    </div>
  );
};
