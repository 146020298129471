import { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import Item from 'components/Distribuidor/Mobile/Item';
import Articulo from 'components/Distribuidor/Mobile/Articulo';
import DetalleEnvio from 'components/Distribuidor/Mobile/DetalleEnvio';
import Modal from 'components/Distribuidor/Mobile/MotivosModal';
import Page from 'components/Distribuidor/Mobile/Page';
import NotFound from 'components/NotFound';
import useAxios from 'hooks/useAxios';
import { estados } from 'utils';
import useAppContext from 'hooks/useAppContext';
import { ReactComponent as FileIcon } from 'assets/file.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg'
import config from '../../../config';

const StyledTitle = styled.h3`
  font-size: 16px;
  letter-spacing: 0.3px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.color};
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSubtitle1 = styled.h4`
  font-size: 21px;
  line-height: 20px;
  justify-content: left;
  font-weight: 700;
  height: 65px;
  padding: 0 15px;
  display: flex;
  align-items: center;
`;

const StyledSubtitle2 = styled.h4`
  font-size: 21px;
  line-height: 20px;
  justify-content: left;
  font-weight: 700;
  height: 65px;
  padding: 0 15px;
  display: flex;
  align-items: center;
`;

const StyledMassageButton = styled.button`
  width: 90%;
  height: 64px;
  margin: 0 auto;
  display: block;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default function DetalleEntrega() {
  const axios = useAxios();
  const history = useHistory();
  const { entregaId } = useParams();
  const { entrega, setEntrega, mensajes, setMensajes } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const hangleMessageButtonClick = () => {
    if (mensajes.length > 0) {
      history.push(`/entregas-mensajes/${entregaId}`);
    } else {
      setShowModal(true);
    }
  };

  const fetchData = async () => {
    try {
      const response1 = await axios.get(`/distribuidor/entregas/${entregaId}`);
      const response2 = await axios.get(`/distribuidor/entregas/${entregaId}/mensajes`);

      setEntrega(response1.data);
      setMensajes(response2.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title={`N° Transporte ${entrega ? entrega.transporte.codigo : '...'}`}>
      {isLoading && <div className="text-center">Cargando...</div>}

      {!isLoading && entrega === null && (
        <NotFound />
      )}

      {!isLoading && entrega && (
        <>
          {showModal && (
            <Modal
              entregaId={entrega.id}
              onCloseModal={() => setShowModal(false)}
            />
          )}

          <StyledTitle color={estados[entrega.estado].color}>
            {estados[entrega.estado].nombre}
          </StyledTitle>

          <StyledSubtitle1>N° Entrega {entrega.codigo}</StyledSubtitle1>

          <Item data={entrega} />

          {entrega.detalle.length > 5 ? (
            <Link to={`/entregas-articulos/${entregaId}`} className="ec__text--primary flex flex-col p-2 bg-white">
              <FileIcon />
              <div className="flex justify-between">
                <strong>Ver detalle de artículos</strong>
                <ArrowRightIcon />
              </div>
            </Link>
          ) : (
            <>
              <StyledSubtitle2>Artículos</StyledSubtitle2>{' '}
              <div className="bg-white p-2">
                {entrega.detalle.map((item) => (
                  <Articulo data={item} key={item.id} />
                ))}
              </div>
            </>
          )}

          <StyledSubtitle2>Datos del envío</StyledSubtitle2>
          <DetalleEnvio data={entrega} />

          {config.marca === 'nk' && (
            <StyledMassageButton className="ec__btn--primary" onClick={hangleMessageButtonClick}>
              Dejar mensaje
            </StyledMassageButton>
          )}
        </>
      )}
    </Page>
  );
};
