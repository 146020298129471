import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { motivos } from 'utils';

const StyledModal = styled.div`
  position: fixed;
  z-index: 10;
  flex-direction: column;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.28);
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Modal = ({ onCloseModal, entregaId }) => {
  const history = useHistory();

  const handleClick = (tipo) => {
    history.push(`/entregas-mensajes/${entregaId}`, {
      tipo,
    });
  };

  return (
    <StyledModal onClick={onCloseModal}>
      <div className="flex flex-col gap-2 bg-white m-auto p-4 rounded-md">
        <div className="text-2xl font-bold mb-2">¿Qué mensaje desea enviar?</div>
        {motivos.map((motivo) => (
          <button
            type="button"
            className="ec__btn--primary w-full py-2 rounded"
            key={motivo.key}
            onClick={() => handleClick(motivo.key)}
          >
            {motivo.descripcion}
          </button>
        ))}
      </div>
    </StyledModal>
  );
};

export default Modal;
