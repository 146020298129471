import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxios from 'hooks/useAxios';
import Page from 'components/Page';
import Input from 'components/Input';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import Toggle from 'components/Toggle';
import useAppContext from 'hooks/useAppContext';
import { parseTelefono } from 'utils';

export default function Perfil() {
  const axios = useAxios();
  const { auth, setAuth } = useAppContext()
  const [confirmacion, setConfirmacion] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      pais: '+54',
    }
  });
  const watchNotificarSms = watch('notificar_sms');

  useEffect(() => {
    setValue('nombre', auth.nombre);
    setValue('email', auth.email);
    setValue('telefono', parseTelefono(auth.telefono));
    setValue('notificar_email', auth.notificar_email);
    setValue('notificar_sms', auth.notificar_sms);
  }, [auth]);

  useEffect(() => {
    setTimeout(() => {
      setConfirmacion(null);
    }, 3000);
  }, [confirmacion]);

  const onSubmit = async (data) => {
    const body = {
      nombre: data.nombre,
      telefono: data.telefono || null,
      notificar_sms: data.notificar_sms ? 1 : 0,
      notificar_email: data.notificar_email ? 1 : 0,
    };

    setIsSubmitting(true);

    try {
      await axios.put('/auth/me', body);

      setAuth({
        ...auth,
        ...body,
      });

      setConfirmacion('Se actualizaron los datos de su perfil');
    } catch (error) {
      const errors = error.response?.data?.errors ?? [];

      Object.keys(errors).forEach((key) => {
        setError(key, { type: 'custom', message: errors[key] });
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Page>
      <Page.Header>
        <Page.Title>Mi perfil</Page.Title>
      </Page.Header>

      {confirmacion && (
        <div className="bg-green-200 rounded-lg py-5 px-6 mb-4 text-base text-green-800 mb-3" role="alert">
          {confirmacion}
        </div>
      )}

      <form method="post" onSubmit={handleSubmit(onSubmit)} autocomplete="off">
        <div className="bg-white rounded-md shadow-md p-6 mb-4">
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            <Input
              id="nombre"
              label="Nombre"
              error={errors.nombre}
              {...register('nombre', {
                required: {
                  value: true,
                  message: 'El nombre es requerido',
                },
              })}
            />

            <div className="flex flex-col gap-2">
              <Toggle
                id="notificar_email"
                label="Notificar por e-mail"
                {...register('notificar_email')}
              />
              <Input
                id="email"
                label="Email"
                disabled
                {...register('email')}
              />
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex flex-col lg:flex-row gap-4">
                <Toggle
                  id="notificar_sms"
                  label="Notificar por mensaje de texto (SMS)"
                  {...register('notificar_sms')}
                />
                <Toggle
                  id="notificar_whatsapp"
                  label="Notificar por Whatsapp (Muy pronto)"
                  disabled
                />
              </div>
              <div className="flex flex-col lg:flex-row gap-2">
                <Input
                  id="pais"
                  label="Código país"
                  width='100px'
                  disabled
                  {...register('pais')}
                />
                <Input
                  type="number"
                  id="telefono"
                  label="Número de teléfono"
                  error={errors.telefono}
                  {...register('telefono', {
                    disabled: !watchNotificarSms,
                    required: {
                      value: true,
                      message: 'El número de teléfono es requerido',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center">
          <button
            type="submit"
            className="ec__btn--primary flex items-center justify-center gap-2 py-2 px-4 rounded w-full lg:w-auto disabled:opacity-75"
            disabled={isSubmitting}
          >
            <SaveIcon />
            {isSubmitting ? 'Guardando...' : 'Guardar'}
          </button>
        </div>
      </form>
    </Page>
  );
}
