import React, { useState } from 'react';
import dayjs from 'dayjs';
import EstadoTransporte from './EstadoTransporte';
import TransportesClientes from './TransportesClientes';
import useAxios from 'hooks/useAxios';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';

export default function TransportesItem({ transporte, selectedOptions }) {
  const axios = useAxios();
  const [show, setShow] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (event) => {
    event.preventDefault();
    setIsDownloading(true);

    try {
      const response = await axios.get(
        '/distribuidor/transportes/download',
        {
          params: {
            ...selectedOptions,
            transporte: transporte.id,
          },
          responseType: 'blob'
        },
      );

      const href = window.URL.createObjectURL(response.data);
      const anchor = document.createElement('a');

      anchor.href = href;
      anchor.setAttribute(
        'download',
        response.headers['x-suggested-filename'] ?? 'Transportes.xlsx',
      );
      anchor.click();

      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
    }

    setIsDownloading(false);
  };

  return (
    <>
      <div className={`${show ? 'ec__btn--tertiary' : 'bg-white'} mb-2 p-2 flex items-center rounded`}>
        <div style={{ width: '30px' }}>
          <button
            onClick={() => setShow(!show)}
            type="button"
            className="focus:outline-none p-2 text-center rounded-md"
          >
            {show ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </button>
        </div>
        <div className="text-center" style={{ width: '200px' }}>
          {transporte.codigo}
        </div>
        <div className="text-center" style={{ width: '200px' }}>
          <EstadoTransporte estado={transporte.estado} />
        </div>
        <div className="text-center" style={{ width: '350px' }}>
          {transporte.transportista?.nombre}
        </div>
        <div className="text-center" style={{ width: '300px' }}>
          {transporte.transportista?.telefono}
        </div>
        <div className="text-center" style={{ width: '300px' }}>
          {transporte.despachado_el
            ? dayjs(transporte.despachado_el).format('DD/MM/YYYY')
            : '-'}
        </div>
        <div
          className="flex items-center justify-center text-center"
          style={{ width: '300px', paddingLeft: '20px' }}
        >
          <button
            onClick={handleDownload}
            disabled={isDownloading}
            className={`flex gap-2 items-center justify-center ${show ? 'text-white' : 'ec__text--tertiary'}`}
          >
            <DownloadIcon />
            <span>
              {isDownloading ? 'Descargando...' : 'Descargar XLSX'}
            </span>
          </button>
        </div>
      </div>
      {show && <TransportesClientes entregas={transporte.entregas} />}
    </>
  );
};
