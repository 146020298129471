import { useEffect } from 'react';

const Modal = ({ children, show, onHide }) => {
  useEffect(() => {
    const close = (e) => {
      if (onHide && e.keyCode === 27) {
        onHide();
      }
    };

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    show && (
      <div
        className="fixed inset-0 overflow-y-auto z-50 "
        role="dialog"
        aria-modal="true"
        aria-hidden="true"
        onClick={() => {
          if (onHide) onHide();
        }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          />
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full h-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full lg:py-0"
            aria-hidden="true"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col justify-between h-full">
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

Modal.Header = ({ children, onClose }) => (
  <div className="flex justify-between items-center">
    <h4 className="text-2xl font-bold">{children}</h4>
    <button type="button" onClick={() => onClose()}>
      <svg
        className="fill-current text-black"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
      </svg>
    </button>
  </div>
);

Modal.Body = ({ children }) => (
  <div className="my-5">
    {children}
  </div>
);

Modal.Footer = ({ children }) => (
  <div className="flex gap-2 justify-end flex-col lg:flex-row">{children}</div>
);

export default Modal;
