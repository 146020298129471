import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { motivos } from 'utils';
import useAppContext from 'hooks/useAppContext';

const StyledModal = styled.div`
  position: fixed;
  z-index: 10;
  flex-direction: column;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.28);
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const StyleWrapper = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 5px 5px 18px 5px;
  border-radius: 8px;
  width: 95%;
  position: relative;
  text-align: center;
`;

const StyledTitle = styled.div`
  letter-spacing: 0.3px;
  font-size: 22px;
  font-weight: bold;
  padding: 19px 0;
  text-align: left;
  width: 94%;
  margin: 0 auto;
`;

const StyledButton = styled.button`
  width: 94%;
  height: 56px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default function ModalMotivos({ onCloseModal, entregaId, showModal }) {
  const history = useHistory();
  const { setMessageType } = useAppContext();

  const handleClick = (type) => {
    setMessageType(type);
    history.push(`/entregas/${entregaId}/mensajes`);
  };

  if (!showModal) {
    return <></>;
  }
  return (
    <StyledModal onClick={onCloseModal}>
      <StyleWrapper>
        <StyledTitle>¿Qué mensaje desea enviar?</StyledTitle>
        {motivos.map((motivo) => (
          <StyledButton
            key={motivo.key}
            onClick={() => handleClick(motivo.key)}
          >
            {motivo.descripcion}
          </StyledButton>
        ))}
      </StyleWrapper>
    </StyledModal>
  );
};
