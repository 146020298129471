import React from 'react'
import styled, { keyframes } from 'styled-components';

const toTop = keyframes`
  from {
    bottom: -5rem;
  } to {
    bottom: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 24px;
  width: 603px;
  left: calc(50% - 603px / 2 - 0.5px);
  bottom: 24px;
  background: #fcfcfc;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  animation: ${toTop} 0.7s;
  p {
    font-size: 16px;
    margin-left: 1rem;
  }
`;

export default function FlashMessage({ message }) {
  return (
    <Wrapper>
      <message.icon />
      <p>{message.text}</p>
    </Wrapper>
  )
}
