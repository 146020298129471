import { useEffect } from 'react';
import styled from 'styled-components';
import Notification from 'components/Distribuidor/Desktop/Notification';
import Page from 'components/Page';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';

const Empty = styled.div`
  height: 200px;
  width: 200px;
  background: #bbb;
`;

export default function Notificaciones() {
  const axios = useAxios();
  const { notificaciones, setNotificaciones } = useAppContext();

  const fetchData = async () => {
    try {
      const response = await axios.get('/distribuidor/notificaciones');
      setNotificaciones(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page>
      <Page.Header>
        <Page.Title>
          Notificaciones
        </Page.Title>
      </Page.Header>

      {notificaciones.length === 0 ? (
        <div className="flex flex-col gap-2 items-center justify-center font-bold text-2xl">
          <Empty />
          <p className="text-center">No tienes notificaciones recientes</p>
        </div>
      ) : (
        notificaciones.map((notificacion) => (
          <Notification notificacion={notificacion} key={notificacion.id} />
        ))
      )}
    </Page>
  );
};
