import { forwardRef } from "react";

const Toggle = forwardRef(
  ({id, label, disabled, size='md', ...rest}, forwardedRef) => {

    const sizes = {
      sm: 'w-9 h-5 after:h-4 after:w-4',
      md: 'w-11 h-6 after:h-5 after:w-5',
      lg: 'w-14 h-7 after:h-6 after:w-6'
    };

    return (
      <label
        htmlFor={id}
        className="flex items-center cursor-pointer"
      >
        <div className="relative flex items-center">
          <input
            type="checkbox"
            className="sr-only peer"
            id={id}
            ref={forwardedRef}
            disabled={disabled}
            {...rest}
          />
          <div className={`${sizes[size]} bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all peer-checked:bg-green-600`}></div>
        </div>
        <span className={`${disabled ? 'text-gray-400' : 'text-gray-900'} ${size === 'lg' ? 'text-md' : 'text-sm'} ml-3 font-medium`}>
          {label}
        </span>
      </label>
    );
  }
);

export default Toggle;
