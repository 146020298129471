import { useEffect, useState } from "react";
import Page from "components/Page";
import RowNotificacion from "components/Comercial/RowNotificacion";
import Filter from "components/Filter";
import NotFound from "components/NotFound";
import FilterSummary from "components/FilterSummary";
import useAxios from "hooks/useAxios";
import useAppContext from "hooks/useAppContext";

const defaultFilters = {
  distribuidores: {
    title: 'Distribuidor',
    placeholder: 'Buscar distribuidor',
    options: [],
  },
};

const Seccion = ({ title, notificaciones, onClick }) => (
  <div className="mb-6">
    <h2 className="mb-2 font-bold">{title}</h2>
    <div className="flex flex-col gap-2">
      {notificaciones.map((notificacion) => (
        <RowNotificacion key={notificacion.id} notificacion={notificacion} onClick={onClick} />
      ))}
    </div>
  </div>
);

const Listado = ({ notificaciones, onClick }) => {
  const leidas = notificaciones.filter((notificacion) => notificacion.leido_el);
  const noLeidas = notificaciones.filter(
    (notificacion) => !notificacion.leido_el,
  );

  return (
    <>
      {noLeidas.length > 0 && (
        <Seccion title="Nuevas" notificaciones={noLeidas} onClick={onClick} />
      )}
      {leidas.length > 0 && <Seccion title="Leídas" notificaciones={leidas} />}
    </>
  );
};

export default function Notificaciones() {
  const axios = useAxios();
  const { notificaciones, setNotificaciones } = useAppContext();
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({});

  const fetchFilters = async () => {
    try {
      const response = await axios.get(`/comercial/distribuidores`, {
        params: {
          sin_entregas: true,
        }
      });

      Object.keys(defaultFilters).forEach((key) => {
        defaultFilters[key].options = response.data.map((distribuidor) => ({
          key: distribuidor.id,
          value: distribuidor.nombre,
        }));
      })

      setFilters({...defaultFilters});
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`/comercial/notificaciones`, {
        params: selectedOptions
      });

      setNotificaciones(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleFilterClean = () => {
    setSelectedOptions({});
  };

  const handleFilterApply = (selected) => {
    setSelectedOptions(selected);
  };

  const handleClick = async (notificacion) => {
    setIsLoading(true);

    try {
      await axios.put(`/comercial/notificaciones/${notificacion.id}/leido`);
      fetchData();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedOptions]);

  return (
    <Page backUrl="/">
      <Page.Header backUrl="/">
        <div className="flex gap-2 flex-col lg:flex-row items-center">
          <Page.Title>
            Notificaciones
          </Page.Title>
          <Filter
            filters={filters}
            initialSelectedOptions={selectedOptions}
            onApply={handleFilterApply}
            onClean={handleFilterClean}
          />
        </div>
      </Page.Header>

      <FilterSummary
        filters={filters}
        selectedOptions={selectedOptions}
        onClean={handleFilterClean}
      />

      {isLoading && <div className="text-gray-400 text-center">Cargando datos...</div>}

      {!isLoading && notificaciones.length == 0 && (
        <NotFound>
          No tienes notificaciones recientes
        </NotFound>
      )}

      {!isLoading && notificaciones.length > 0 && (
        <Listado notificaciones={notificaciones} onClick={handleClick} />
      )}
    </Page>
  );
}
