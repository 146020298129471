import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Notificacion from 'components/Distribuidor/Mobile/Notificacion';
import useAxios from 'hooks/useAxios';
import Page from 'components/Distribuidor/Mobile/Page';
import useAppContext from 'hooks/useAppContext';

const Empty = styled.div`
  height: 200px;
  width: 200px;
  background: #bbb;
`;

export default function Notificaciones() {
  const axios = useAxios();
  const { notificaciones, setNotificaciones } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get('/distribuidor/notificaciones');
      setNotificaciones(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false)
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Notificaciones">
      {isLoading && <div className="text-center">Cargando...</div>}

      {!isLoading && notificaciones.length === 0 && (
        <div className="flex flex-col gap-2 items-center justify-center font-bold text-2xl">
          <Empty />
          <p className="text-center">No tienes notificaciones recientes</p>
        </div>
      )}

      {!isLoading && notificaciones.map((item) =>
        <Notificacion data={item} key={item.id} />
      )}
    </Page>
  );
};
