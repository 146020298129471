import { Route, Switch } from 'react-router-dom';
import Home from './pages/Comercial/Home';
import DetalleEntrega from './pages/Comercial/Entrega';
import NotFound from './components/NotFound';
import PoliticaPrivacidad from './pages/PoliticaPrivacidad';

export default function RoutesComercialKam() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/entregas/:entregaId" component={DetalleEntrega} />
      <Route path="/privacidad" component={PoliticaPrivacidad} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
