import notFoundNk from 'assets/not-found-nk.png';
import notFoundNidera from 'assets/not-found-nidera.png';
import config from '../config';

export default function NotFound({ children }) {
  return (
    <section className="flex flex-col items-center justify-center">
      <img
        src={config.marca === 'nd' ? notFoundNidera : notFoundNk}
        alt="No encontrado"
        width="500"
      />
      {children ?? 'Ups! La página que está buscando no existe'}
    </section>
  );
}
