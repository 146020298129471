import despachoIcon from 'assets/entrega-despachado.svg';
import demoraIcon from 'assets/entrega-demoras.svg';
import proximidadIcon from 'assets/entrega-proximidad.svg';
import cancelacionIcon from 'assets/entrega-cancelacion.svg';

export default function NotificacionEstado({ tipo }) {
  const icons = {
    despacho: despachoIcon,
    demora: demoraIcon,
    proximidad: proximidadIcon,
    cancelacion: cancelacionIcon,
  };

  return <img src={icons[tipo]} alt={tipo} width="50" height="50" />;
};
