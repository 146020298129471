import { Link, NavLink } from 'react-router-dom';
import useAppContext from 'hooks/useAppContext';
import logoEncamino from 'assets/logo-encamino.png';
import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back.svg';
import { ReactComponent as BoxIcon } from 'assets/box.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import NotificacionIcon from './NotificacionIcon';

const NavItem = ({ to, children }) => {
  return (
    <NavLink
      to={to}
      className="ec__navbar-item flex gap-2 justify-center items-center px-6 h-full"
      activeClassName="ec__navbar--active"
      exact
    >
      {children}
    </NavLink>
  );
};

export default function NavBar ({ backUrl }) {
  const { auth } = useAppContext();

  return (
    <header className="ec__navbar w-full flex items-center justify-between px-8">
      {backUrl ? (
        <Link className="lg:hidden" to={backUrl}>
          <ArrowBackIcon className="ec__navbar-item" />
        </Link>
      ) : (
        <div className="lg:hidden"></div>
      )}

      <img src={logoEncamino} alt="En Camino" className="ec__encamino_logo my-4" />

      <nav className="hidden lg:flex items-center justify-center h-full text-center">
        {auth.isComercialKam && (
          <>
            <NavItem to='/'>
              <BoxIcon /> Entregas
            </NavItem>
            <NavItem to='/notificaciones'>
              <NotificacionIcon /> Notificaciones
            </NavItem>
          </>
        )}

        {auth.isComercial && (
          <>
            <NavItem to='/'>
              <BoxIcon /> Entregas
            </NavItem>
          </>
        )}

        {auth.isDistribuidor && (
          <>
            <NavItem to='/'>
              <BoxIcon /> Pedidos
            </NavItem>
            <NavItem to='/notificaciones'>
              <NotificacionIcon /> Notificaciones
            </NavItem>
            <NavItem to='/receptores'>
              <UserIcon /> Receptores
            </NavItem>
            <NavItem to='/perfil'>
              <div className="ec__text--primary bg-white flex items-center justify-center rounded-full w-10 h-10 uppercase">
                {auth.nombre[0]}
              </div>
            </NavItem>
          </>
        )}
      </nav>

      <div className="lg:hidden"></div>
    </header>
  );
};
