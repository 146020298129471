import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import locale from 'dayjs/locale/es';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import NotificacionEstado from 'components/NotificacionEstado';

dayjs.extend(relativeTime);
dayjs.locale(locale);

const Notification = ({ notificacion }) => {
  const axios = useAxios();
  const { setNotificaciones } = useAppContext();

  const handleClick = async () => {
    if (notificacion.leido_el === null) {
      try {
        await axios.put(`/distribuidor/notificaciones/${notificacion.id}/leido`);

        const response = await axios.get(`/distribuidor/notificaciones`);

        setNotificaciones(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div
      className={`flex items-center p-4 rounded-md mb-3 w-full cursor-pointer ${
        notificacion.leido_el === null
          ? 'bg-white'
          : 'bg-gray-100 text-gray-400'
      }`}
      onClick={handleClick}
      aria-hidden="true"
    >
      <div className="mx-6">
        <NotificacionEstado tipo={notificacion.tipo} />
      </div>
      <div className="mr-auto">
        <div className="font-bold">{notificacion.asunto}</div>
        <div>{notificacion.mensaje}</div>
        <div className="text-gray-400">
          {dayjs(notificacion.creado_el).fromNow()}
        </div>
      </div>
      {notificacion.entrega ? (
        <div>
          <div className="font-bold text-right">Nro. de Entrega</div>
          <div className="text-gray-400 text-right">
            {notificacion.entrega.codigo}
          </div>
        </div>
      ) : (
        <div>
          <div className="font-bold text-right">Nro. de Transporte</div>
          <div className="text-gray-400 text-right">
            {notificacion.transporte.codigo}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
