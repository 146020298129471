import useAxios from "hooks/useAxios";
import { createContext, useEffect, useState } from "react";

export const AppContext = createContext({});

export function AppProvider ({ children }) {
  const axios = useAxios();
  const [auth, setAuth] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [notificaciones, setNotificaciones] = useState([]);
  const [mensajes, setMensajes] = useState([]);
  const [entrega, setEntrega] = useState(null);

  const fetchNotificaciones = async () => {
    try {
      const response = await axios.get(
        auth.isDistribuidor
          ? 'distribuidor/notificaciones'
          : 'comercial/notificaciones'
      );

      setNotificaciones(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (auth) {
      fetchNotificaciones();
    }
  }, [auth]);

  return (
    <AppContext.Provider value={{
      auth,
      setAuth,

      flashMessage,
      setFlashMessage,

      notificaciones,
      setNotificaciones,

      mensajes,
      setMensajes,

      entrega,
      setEntrega,
    }}>
      {children}
    </AppContext.Provider>
  )
}
