import styled from 'styled-components';
import NotificacionEstado from 'components/NotificacionEstado';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';

const StyledWrapperImg = styled.div`
  padding: 0;

  & img {
    max-width: 50px;
  }
`;

const StyledWrapperNotification = styled.div`
  display: flex;
  background: ${(props) => (props.read ? '#f8f8f8' : 'white')};
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 18px;
  position: relative;
  color: #4D4D4D;
  cursor: pointer;
  margin-bottom: 10px;
`;

const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 17px;
  color: ${(props) => (props.read ? '#a1a1a1' : '#3b3b3b')};
`;

const StyledSubtitle = styled.div`
  font-size: 14px;
  color: #B4B4B4;
  padding-top: 3px;
`;

const StyledText = styled.div`
  font-size: 17px;
  line-height: 20px;
  padding-top: 5px;
  color: ${(props) => (props.read ? '#a1a1a1' : '#818181')};
`;

const StyledDate = styled.div`
  font-size: 12px;
  color: #B4B4B4;
  position: absolute;
  top: 7px;
  right: 20px;
`;

const StyledWrapperContents = styled.div`
  padding-left: 4.5%;
`;

export default function Notificacion({ data }) {
  const axios = useAxios();
  const { setNotificaciones } = useAppContext();

  const handleClick = async () => {
    if (data.leido_el !== null) {
      return
    }

    try {
      await axios.put(`/distribuidor/notificaciones/${data.id}/leido`);

      const response = await axios.get('/distribuidor/notificaciones');

      setNotificaciones(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledWrapperNotification read={data.leido_el} onClick={handleClick}>
      <StyledDate>{data.creado_hace}</StyledDate>
      <StyledWrapperImg>
        <NotificacionEstado tipo={data.tipo} />
      </StyledWrapperImg>
      <StyledWrapperContents>
        <StyledTitle read={data.leido_el}>{data.asunto}</StyledTitle>
        {data.entrega ? (
          <StyledSubtitle read={data.leido_el}>
            Nro. de Entrega {data.entrega.codigo}
          </StyledSubtitle>
        ) : (
          <StyledSubtitle read={data.leido_el}>
            Nro. de Transporte {data.transporte.codigo}
          </StyledSubtitle>
        )}
        <StyledText read={data.leido_el}>{data.mensaje}</StyledText>
      </StyledWrapperContents>
    </StyledWrapperNotification>
  );
};
