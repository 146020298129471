import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RowReceptor from 'components/RowReceptor';
import Page from 'components/Page';
import useAxios from 'hooks/useAxios';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';

const LIMITE_RECEPTORES = 50;

const CreateButton = () => (
  <Link
    to="/receptores-nuevo"
    className="ec__btn--primary p-3 rounded-md text-white"
  >
    Crear nuevo receptor
  </Link>
);

export default function Receptor() {
  const axios = useAxios();
  const [receptores, setReceptores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/distribuidor/receptores');
      setReceptores(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page>
      <Page.Header>
        <div className="flex justify-between items-center">
          <Page.Title>
            Lista de Receptores
          </Page.Title>

          {receptores.length >= LIMITE_RECEPTORES && (
            <div className="text-red-500">
              Alcanzó el límite de receptores ({LIMITE_RECEPTORES})
            </div>
          )}

          {receptores.length > 0 && receptores.length < LIMITE_RECEPTORES && (
            <CreateButton />
          )}
        </div>
      </Page.Header>

      {isLoading && <div className="text-center text-gray-400">Cargando...</div>}

      {!isLoading && receptores.length === 0 && (
        <NotFound>
          <p className="mb-2">
            Aún no tiene receptores
          </p>
          <CreateButton />
        </NotFound>
      )}

      {!isLoading && receptores.map((receptor, index) => (
        <RowReceptor
          key={receptor.id}
          receptor={receptor}
          editPath={`/receptores-editar/${receptor.id}`}
          habilitar={(body) => axios.patch(`/distribuidor/receptores/${receptor.id}`, body)}
        />
      ))}
    </Page>
  );
};
