const EstadoTransporte = ({ estado }) => {
  const estados = {
    planificado: {
      text: 'Planificado',
      backgroundColor: '#3e3e3e',
      fontColor: 'white',
    },
    encurso: {
      text: 'En curso',
      backgroundColor: '#0db030',
      fontColor: 'white',
    },
    despachado: {
      text: 'Despachado',
      backgroundColor: '#010066',
      fontColor: 'white',
    },
    finalizado: {
      text: 'Finalizado',
      backgroundColor: '#b4b4b4',
      fontColor: 'white',
    },
  };

  return (
    <span
      className="inline-block py-1 rounded-md text-sm font-bold"
      style={{
        backgroundColor: estados[estado].backgroundColor,
        color: estados[estado].fontColor,
        width: '120px',
      }}
    >
      {estados[estado].text}
    </span>
  );
};

export default EstadoTransporte;
