import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "components/Page";
import NotFound from "components/NotFound";
import useAxios from "hooks/useAxios";
import NavBar from "components/NavBar";

const Card = ({ double, content, title, sectionStyle, articleStyle }) => {
  if (double) {
    return (
      <section
        className={`${sectionStyle} bg-white rounded-[8px] shadow-[0_2px_10px_rgba(0,0,0,0.05)] mb-2`}
      >
        <article className="border-b flex flex-col justify-center px-5 py-5">
          <h3 className="ec__text--primary font-bold pb-1">
            {title[0]}
          </h3>
          <p>{content[0]}</p>
        </article>

        <article className="px-5 py-5">
          <h3 className="ec__text--primary font-bold pb-1">
            {title[1]}
          </h3>
          <p>{content[1]}</p>
        </article>
      </section>
    );
  } else {
    return (
      <article
        className={`px-5 py-5 shadow-[0_2px_10px_rgba(0,0,0,0.05)] mb-2 bg-white rounded-[8px] ${articleStyle}`}
      >
        <h3 className="ec__text--primary font-bold pb-1">
          {title}
        </h3>
        <p>{content}</p>
      </article>
    );
  }
};

export default function DetalleEntrega() {
  const axios = useAxios();
  const { entregaId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [entrega, setEntrega] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`/comercial/entregas/${entregaId}`);
      setEntrega(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page backUrl="/">
      <Page.Header backUrl="/">
        <Page.Title>
          Detalle de la entrega
        </Page.Title>
      </Page.Header>

      {!isLoading && !entrega && (
         <NotFound>
          La entrega que estás buscando no existe
        </NotFound>
      )}

      {!isLoading && entrega && (
        <>
          <section className="flex flex-col">
            <h2 className="font-bold mb-2">Detalles:</h2>

            <section className="flex lg:flex-row flex-col lg:gap-2">
              <Card
                double
                title={['Destinatario', 'Desc. Destinatario']}
                content={[
                  entrega.destinatario.codigo,
                  entrega.destinatario.nombre,
                ]}
                sectionStyle="lg:basis-[50%]"
              />
              <Card
                double
                title={['Cliente Ppal', 'Desc.Cliente Ppal.']}
                content={[
                  entrega.cliente.codigo,
                  entrega.cliente.nombre,
                ]}
                sectionStyle="lg:basis-[50%]"
              />
            </section>

            <section className="flex lg:flex-row flex-col lg:gap-2">
              <Card
                title="Nro de Remito"
                content={entrega.numero_remito}
                articleStyle="lg:basis-[33.3%]"
              />
              <Card
                title="Nro de entrega"
                content={entrega.codigo}
                articleStyle="lg:basis-[33.3%]"
              />
              <Card
                title="Nro de pedido"
                content={entrega.numero_pedido}
                articleStyle="lg:basis-[33.3%]"
              />
            </section>

            <section className="flex lg:flex-row flex-col lg:gap-2">
              <Card
                title="Transporte"
                content={entrega.transporte.transportista?.nombre}
                articleStyle="lg:basis-[50%]"
              />
              <Card
                title="Fecha de despacho"
                content={entrega.fecha_despacho}
                articleStyle="lg:basis-[50%]"
              />
            </section>
          </section>

          <h2 className="font-bold mb-2 mt-6">Artículos:</h2>
          <section className="mb-6 w-full overflow-y-hidden">
            <table className="bg-white border w-full lg:min-w-min min-w-[1600px] table-auto border-collapse overflow-x-scroll">
              <thead className="ec__thead text-left">
                <tr>
                  <th className="p-2">Código material</th>
                  <th className="p-2">Descripción Material</th>
                  <th className="p-2">Cantidad</th>
                  <th className="p-2">Lote</th>
                  <th className="p-2">Placa</th>
                </tr>
              </thead>
              <tbody>
                {entrega.detalle.map((detalle, index) => (
                  <tr key={index}>
                    <td className="p-2">{detalle.material_codigo}</td>
                    <td className="p-2">{detalle.material_descripcion}</td>
                    <td className="p-2">{detalle.cantidad}</td>
                    <td className="p-2">{detalle.lote}</td>
                    <td className="p-2">{detalle.placa}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </>
      )}
    </Page>
  );
}
