import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import RoutesComercialKam from 'RoutesComercialKam';
import RoutesComercial from 'RoutesComercial';
import RoutesDistribuidor from 'RoutesDistribuidor';
import RoutesDistribuidorMobile from 'RoutesDistribuidorMobile';
import Theme from 'components/Theme';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import config from './config';

// config.marca = 'nidera';
// config.marca = 'nk';
// config.marca = 'sps';

export default function App() {
  const axios = useAxios();
  const { auth, setAuth } = useAppContext();

  const fetchMe = async () => {
    try {
      const response = await axios.get('/auth/me');
      const data = response.data;

      setAuth({
        ...data,
        isDistribuidor: data.rol === 'distribuidor',
        isComercial: data.rol === 'comercial' && !data.comercial.es_kam,
        isComercialKam: data.rol === 'comercial' && data.comercial.es_kam,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMe();
  }, []);

  if (!auth) {
    return null;
  }

  return (
    <>
      <Theme brand={config.marca} />
      {auth.isComercialKam && <RoutesComercialKam />}
      {auth.isComercial && <RoutesComercial />}
      {auth.isDistribuidor &&
        (isMobile ? <RoutesDistribuidorMobile /> : <RoutesDistribuidor />)}
    </>
  );
}
