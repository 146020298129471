import styled from 'styled-components';
import Modal from 'components/Modal';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';

const H4 = styled.h4`
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #000000;
`;

export default function ReceptorDelete({
  show,
  receptor,
  onDelete,
  onClose,
}) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <div className="flex gap-2 items-center">
          <WarningIcon />
          <H4>Eliminar Receptor</H4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>El siguiente receptor será eliminado de la lista. Esta acción no se puede deshacer.</p>
        <strong>Receptor: {`${receptor.nombre} ${receptor.apellido}`}</strong>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="ec__btn gap-2 bg-red-500 text-white lg:w-1/2"
          onClick={() => onDelete()}
        >
          <TrashIcon /> Eliminar receptor
        </button>
        <button
          type="button"
          className="ec__btn ec__text--primary lg:w-1/2"
          onClick={onClose}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
}
