import Page from "components/Page";

export default function PoliticaPrivacidad() {
  return (
    <Page>
      <Page.Header>
        <Page.Title>
          POLÍTICA DE PRIVACIDAD
        </Page.Title>
      </Page.Header>

      <p>
        Al utilizar este Sitio y la Aplicación (en adelante, el Portal), usted,
        como usuario nos confía su información. Mediante la presente Política de
        Privacidad (en adelante, la Política), informamos a nuestros usuarios como
        tratamos su información personal.
      </p>
      <p>
        Sírvase leer esta política cuidadosamente. Al visitar este sitio web y/o
        la Aplicación y utilizar los servicios que aquí se brindan, Ud. manifiesta
        su consentimiento a la utilización por parte de SYNGENTA AGRO S.A. (en
        adelante SYNGENTA) de sus datos personales que obran en esta Política
      </p>
      <p>
        Si nuestra Web o nuestra Aplicación para dispositivos móviles contiene
        enlaces que conducen a páginas web de otro proveedor, nuestra Política no
        se aplica a esa página web o la que se le remita.
      </p>
      <p>
        <strong>ALCANCES DE ESTA POLÍTICA</strong>
      </p>
      <p>
        Esta Política aplica a todos los usurarios de nuestro Portal, y es
        necesario prestar su consentimiento expreso, libre e informado al momento
        de registrarse como usuario. Por lo tanto, con su consentimiento a la
        siguiente Política de Privacidad, Usted autoriza y da su conformidad a la
        recopilación, procesamiento, uso y tratamiento de sus datos personales por
        SYNGENTA y empresas del grupo SYNGENTA, de conformidad con la normativa
        vigente en Argentina y en particular con la Ley 25.326 de Protección de
        Datos, así como cualquier norma que la reglamente, modifique o reemplace.
      </p>
      <p>
        Si usted tiene cualquier pregunta sobre esta Política o el tratamiento que
        le damos a sus datos personales, por favor escribanos un email a
        [_______________]
      </p>
      <p>
        <strong>RESPONSABILIDAD DEL USUARIO</strong>
      </p>
      <p>
        Los usuarios garantizan y responden, en cualquier caso, sobre la
        veracidad, exactitud, vigencia y autenticidad de la Información Personal
        facilitada, y se comprometen a mantenerla debidamente actualizada.
      </p>
      <p>
        <strong>RESPONSABLE DEL TRATAMIENTO DE LA BASE DE DATOS</strong>
      </p>
      <p>
        El responsable de la base de datos es SYNGENTA AGRO S.A., CUIT
        30-71198576-6, con domicilio legal en 25 de Mayo 749, piso 1, of. 3, de la
        Ciudad Autónoma de Buenos Aires, Argentina, y que los datos de carácter
        personal proporcionados a través del Portal, así como los que pudiera
        facilitar en el futuro en el marco de su relación con nosotros, son
        almacenados en nuestros servidores para conservarse bajo seguridad de
        estos.
      </p>
      <p>
        A continuación, informamos a nuestros usuarios qué datos recolectamos,
        para qué propósitos y bajo qué circunstancias los compartimos con otros
        <strong>.</strong>
      </p>
      <p>
        Los datos personales son información con la que podemos relacionar a
        nuestros usuarios directa o indirectamente, tales como su nombre y
        apellidos, dirección, número de teléfono, fecha de nacimiento, datos de
        localización o dirección de correo electrónico y todo otro dato que usted
        nos provea.
      </p>
      <p>
        El Portal recopila información de identificación personal solamente de
        aquellas personas que voluntariamente optan por brindar dicha información
        a través del Portal. (Se hace referencia en esta Política a dicha
        información como la “Información Personal”.) La recopilación de
        Información Personal podrá llevarse a cabo de diversas maneras:
      </p>
      <ul>
        <li>
          <p>
            Este Portal puede permitir a los usuarios solicitar una
            “identificación de usuario” y una contraseña que podrán utilizarse en
            ocasión de futuras visitas al sitio y/o a la Aplicación para
            tecnologías móviles. Puede solicitarse a los visitantes del sitio que
            indiquen su nombre, ubicación geográfica y determinada información
            respecto de sus negocios u ocupaciones o algún otro dato que resulte
            de interés para el sitio. Asimismo, puede permitir a los usuarios
            proporcionar una dirección de e-mail para suscribirse a los boletines
            informativos de Syngenta. En otros casos se incluyen formularios a
            través de los cuales los usuarios pueden dirigir sus consultas o
            comentarios a Syngenta. (En esta Política, se hará referencia a dicha
            información como la &quot;Información del Usuario.&quot;)
          </p>
        </li>

        <li>
          <p>
            En el caso que este Sitio brinde salas de chat o foros de discusión
            donde los usuarios pueden registrarse y dejar sus mensajes o
            comentarios. La participación en dichas áreas de discusión es
            absolutamente voluntaria. Ninguna información que los usuarios
            incluyan en dicha área es privada, en la medida que otros usuarios de
            dicha área pueden tener acceso a la misma. Por ello, cualquier
            Información Personal que el usuario decidiera incluir en dicha área no
            estará sujeta a la presente Política de Privacidad.
          </p>
        </li>

        <li>
          <p>
            En forma periódica, nuestro sitio podrá solicitar información
            adicional a los usuarios mediante encuestas o concursos. La
            participación en dichas encuestas o concursos es completamente
            voluntaria y, por ende, el usuario tiene la opción de brindar o no
            cualquier información adicional que pudiera serle solicitada. La
            información solicitada a través de encuestas y concursos comprende
            información adicional de tipo demográfico. (En esta Política, se hace
            referencia a dicha información como a la “Información de Encuesta”).
          </p>
        </li>

        <li>
          <p>
            Además, a la Información Personal, este sitio registra información
            sobre el visitante procedente del navegador utilizado para acceder al
            sitio (por ejemplo, Internet Explorer, Netscape, etc.). Ello incluye
            información sobre la dirección IP del visitante, las páginas del sitio
            que son visitadas desde dicha dirección y el sitio web que fue
            visitado por el usuario inmediatamente antes del Portal de Syngenta.
            (En esta Política, se hace referencia a dicha información como la
            &quot;Información de Visita.&quot;) Dicha Información de Visita no
            permite la identificación personal del usuario individual que tiene
            acceso, por lo cual la Información de Visita no es considerada como
            Información Personal. La Información de Visita será utilizada por
            Syngenta para diagnosticar problemas con sus servidores web, para
            administrar, diseñar y construir sus sitios web, rastrear los
            movimientos del visitante en dichos sitios y recopilar extensa
            información demográfica para uso global exclusivamente.
          </p>
        </li>
      </ul>

      <p>
        Los campos necesarios para su registro están marcados como obligatorios.
        Sin esta información no podemos brindar satisfactoriamente los servicios
        de SYNGENTA. El resto de la información que no se muestra como campos
        obligatorios es opcional. Si usted nos proporciona voluntariamente esta
        información, nos ayudará a mejorar el servicio que le préstamos. Sin
        embargo, la omisión de esta información no le impedirá aprovechar todas
        prestaciones ofrecidas en este Portal. El alcance depende de la
        información que usted proporcione. Pero la información básica es la
        siguiente:
      </p>
      <table cellPadding="7" cellSpacing="0">
        <col />

        <col />

        <tr valign="top">
          <td>
            <p>
              <strong>Información de cuenta:</strong>
            </p>
          </td>
          <td>
            <p>
              Esta información puede incluir el nombre completo, domicilio, fecha
              de nacimiento, número telefónico, email, información de pago
              (incluida información de verificación de pago vinculada), documento
              de identidad o número de documentos de identificación ante
              organismos públicos.
            </p>
          </td>
        </tr>
        <tr valign="top">
          <td bgcolor="#f2f2f2">
            <p>
              <strong>Ubicación</strong>
            </p>
          </td>
          <td bgcolor="#f2f2f2">
            <p>
              Si el usuario da permiso para procesar sus datos de ubicación
              recopilamos dicha información precisa o aproximada a través de datos
              como GPS, dirección IP y wifi.
            </p>
          </td>
        </tr>
        <tr valign="top">
          <td>
            <p>
              <strong>Información de acceso:</strong>
            </p>
          </td>
          <td>
            <p>Usuario o Mail y contraseña.</p>
          </td>
        </tr>
        <tr valign="top">
          <td bgcolor="#f2f2f2">
            <p>
              <strong>
                Información del dispositivo desde el que visita nuestro sitio web:
              </strong>
            </p>
          </td>
          <td bgcolor="#f2f2f2">
            <p>
              Identificación del dispositivo, sistema operativo y/u otros
              identificadores del dispositivo, hardware.
            </p>
          </td>
        </tr>
        <tr valign="top">
          <td>
            <p>
              <strong>Información de conexión:</strong>
            </p>
          </td>
          <td>
            <p>Hora, fecha, duración de uso del sitio web y origen.</p>
          </td>
        </tr>
        <tr valign="top">
          <td bgcolor="#f2f2f2">
            <p>
              <strong>Información de comunicación:</strong>
            </p>
          </td>
          <td bgcolor="#f2f2f2">
            <p>Comentarios, comunicaciones generales.</p>
          </td>
        </tr>
        <tr valign="top">
          <td>
            <p>
              <strong>Historial de entregas</strong>
            </p>
          </td>
          <td>
            <p>
              Información sobre detalle del pedido, la información de entrega.
            </p>
          </td>
        </tr>
        <tr valign="top">
          <td bgcolor="#f2f2f2">
            <p>
              <strong>Otra información:</strong>
            </p>
          </td>
          <td bgcolor="#f2f2f2">
            <p>
              Información que el usuario nos provea voluntariamente mientras
              utiliza el sitio web o la aplicación e información de otras fuentes
              tales como redes sociales o aplicaciones que utilicen nuestra
              interfaz de conexión u otras bases de datos públicas.
            </p>
          </td>
        </tr>
      </table>
      <p>
        En cualquiera de los casos antes indicados, por favor tenga presente que
        toda información que usted nos provea según lo antes descripto, SYNGENTA
        la considera información personal suya y que Ud. declara que la
        información brindada se corresponde con sus datos personales y que los
        mismos son correctos y actualizados.
      </p>
      <p>
        Solo recolectamos sus datos personales cuando es necesario y la finalidad
        es lícita y previamente definida. SYNGENTA usa su información personal
        para los propósitos especificados en esta Política o en las secciones
        relevantes de nuestro Portal.
      </p>
      <p>
        El Usuario presta su pleno y total consentimiento para que SYNGENTA
        recopile sus datos personales y los utilice, transfiera, almacene y ceda,
        de conformidad con el uso de información previsto en esta Política de
        Privacidad.
      </p>
      <p>
        <strong>ACTUALIZACIÓN DE LA INFORMACIÓN</strong>
      </p>
      <p>
        Si su información cambia o ha cambiado, es usted el responsable de
        modificarla en el Portal. Esto permitirá el correcto funcionamiento del
        sitio y/o la Aplicación<strong>.</strong>
      </p>
      <p>
        <strong>TRANSFERENCIA DE DATOS</strong>
      </p>
      <p>
        Procesamos sus datos principalmente dentro de Argentina, Uruguay y dentro
        de la Unión Europea (UE). Sin embargo, debido a que Syngenta es una
        empresa del GRUPO SYNGENTA y sus subsidiarias y/o vinculadas operan en
        varios países alrededor del mundo, y como consecuencia de que sus
        servidores web que alojan sus sitios web se encuentran ubicados en
        numerosas instalaciones, es posible que la Información Personal sobre
        visitantes a este sitio web pueda transferirse de un país a otro con
        niveles no adecuados de legislación conforme la Ley 25.326.
      </p>
      <p>
        Cuando usted ingresa Información Personal al Sitio Web y/o la Aplicación
        de Syngenta, se asumirá también están dando su consentimiento sobre la
        transferencia de dicha información fuera de su país a menos que usted
        indique específicamente lo contrario al momento de ingresar dicha
        información al sitio web y nos informe de su decisión siguiendo las
        instrucciones en la página web seleccionando la opción habilitada para tal
        efecto.
      </p>
      <p>
        <strong>DERECHOS DEL USUARIO</strong>
      </p>
      <p>
        SYNGENTA es responsable de la base de datos y ante quien podrán ejercerse
        los derechos previstos en la Ley 25.326 de Protección de Datos y aquellas
        normas que la modifiquen o reemplacen, acreditando debidamente su
        identidad o representación. De acuerdo con dicha normativa, usted podrá,
        previa acreditación de su identidad, solicitar, gratuitamente, el acceso,
        la rectificación, actualización inclusión o modificación, cancelación y
        cualquier otra acción relativa a su información personal de conformidad
        con Ley 25.326 de Protección de Datos.
      </p>
      <p>
        SYNGENTA retiene la información del usuario y el perfil mientras continúe
        su relación comercial con Syngenta.
      </p>
      <p>
        Ud. tiene el derecho de acceder, dentro de los 10 (diez) días corridos
        desde la acreditación de su identidad, en forma gratuita a sus datos
        personales, en intervalos no inferiores a seis meses, salvo que se
        acredite un interés legítimo.
      </p>
      <p>
        Para la rectificación, actualización, eliminación, así como a la oposición
        del tratamiento de los datos personales, el titular de los mismo podrá
        ejercer tales derechos dentro de los 5 (cinco) días hábiles de acreditada
        su identidad. Syngenta procederá con dicha solicitud o informará de las
        razones por las que estime no corresponde en el plazo anteriormente
        indicado.
      </p>
      <p>
        Puedes gestionar y solicitar la eliminación de tus datos personales
        [_____________]
      </p>
      <p>
        <i>
          La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano
          de Control de la Ley N° 25.326, tiene la atribución de atender las
          denuncias y reclamos que interpongan quienes resulten afectados en sus
          derechos por incumplimiento de las normas vigentes en materia de
          protección de datos personales.
        </i>
      </p>
      <p>
        <strong>COOKIES</strong>
      </p>
      <p>
        Una cookie es un pequeño archivo de datos que el sitio web pasa al disco
        duro del visitante una vez que dicho sitio se ha visitado. El archivo
        cookie contiene información que permite identificar a un visitante que
        regresa cada vez que éste visita un sitio. Los datos son encriptados para
        mayor seguridad. Es posible que el Portal utilice cookies como un modo de
        medir los parámetros de actividad y tráfico en el sitio a los fines de
        mejorar continuamente la experiencia del visitante. Las cookies brindan a
        los visitantes la facilidad de no tener que ingresar más de una vez una
        contraseña en un sitio de Syngenta, ahorrando tiempo al visitante mientras
        visita nuestro sitio.
      </p>
      <p>
        Puede configurarse un navegador de modo tal que avise a los usuarios antes
        de aceptar las cookies. Los usuarios pueden optar por rechazar las cookies
        quitándolas del navegador. No es necesario que los usuarios tengan las
        cookies activadas para visitar nuestro sitio web, aunque las necesitarán
        para hacer uso de ciertas características personalizables de nuestro sitio
        web, tales como la exhibición automática de pronósticos locales de clima y
        cosechas, el acceso a determinadas áreas de nuestro sitio web y la
        realización de operaciones en el sitio de nuestra empresa.
      </p>
      <p>
        <strong>USO DE SU INFORMACIÓN PERSONAL POR SYNGENTA</strong>
      </p>
      <p>
        Su Información Personal podrá utilizarse para comunicarnos con usted
        respecto del uso de nuestro sitio, hacerle saber los cambios introducidos
        en nuestros servicios y nuestro sitio o para brindarle nuestros servicios
        o mejorar nuestro conocimiento de lo que los visitantes quieren y
        necesitan ver en el Portal. Específicamente:
      </p>
      <ul>
        <li>
          <p>
            La Información del Usuario puede ser utilizada por Syngenta
            principalmente para brindar el servicio de seguimiento digital en
            tiempo real de su pedido
          </p>
        </li>

        <li>
          <p>
            Además, para incrementar la posibilidad de uso y capacidad de
            respuesta del Portal, garantizando así que la información sea oportuna
            y relevante y satisfaga las necesidades de nuestros visitantes.
            Syngenta podrá también utilizar la información que los visitantes
            ofrezcan en forma voluntaria a través de este sitio para brindar
            información y material de promoción y procesar las posibles
            solicitudes específicas de información de los visitantes.
          </p>
        </li>

        <li>
          <p>
            La Información sobre Entregas y Detalles de Pedidos será utilizada por
            Syngenta para procesar y llevar a cabo las operaciones requeridas por
            el visitante respecto de bienes o servicios.
          </p>
        </li>

        <li>
          <p>
            La Información de Encuesta se utilizará de modo similar a la
            Información del Usuario, aun cuando fundamentalmente se utilizará en
            forma global para brindar a Syngenta información sobre necesidades de
            clientes, demanda del mercado, etc.
          </p>
        </li>
      </ul>

      <p>
        Asimismo, debe saber que tanto su Información Personal como la Información
        de Visita recopiladas en el Portal de Syngenta pueden ser utilizadas por
        personal de seguridad de Syngenta en caso de que lo considerara necesario
        para proteger a Syngenta de una amenaza u otro riesgo percibido contra la
        seguridad, pudiendo dicha información compartirse con las autoridades de
        aplicación de la ley, de corresponder.
      </p>
      <p>
        <strong>POSIBILIDAD DE COMPARTIR Y REVELAR INFORMACIÓN </strong>
      </p>
      <p>
        Syngenta podrá compartir la Información Personal con terceros para
        determinados fines:
      </p>
      <ul>
        <li>
          <p>
            Podemos brindar información de contacto a nuestros socios comerciales,
            anunciantes y demás interesados para que puedan comunicarse
            directamente con usted para brindarle información acerca de sus
            productos y servicios. (Conforme dicho término es utilizado en esta
            Política, el término “Socios Comerciales” hace referencia a los
            negocios que no son propios de Syngenta ni son operados por Syngenta
            pero que brindan productos o servicios compatibles que pueden ser
            deseados por o potencialmente útiles para los visitantes del sitio web
            de Syngenta.)
          </p>
        </li>

        <li>
          <p>
            Brindaremos datos globales sobre productos y servicios adquiridos,
            intereses de visitantes, información demográfica y de otra naturaleza
            recopilada de nuestro Portal a nuestros Socios Comerciales,
            anunciantes y demás partes interesadas para que puedan tomar
            conocimiento de los productos que resultan más populares para nuestros
            clientes. Los datos globales se proporcionarán en forma anónima como
            información estadística de grupo y no estarán vinculados a ninguna
            persona en particular.
          </p>
        </li>

        <li>
          <p>
            Podemos asociarnos con terceros para brindar servicios específicos.
            Cuando el usuario se suscriba a dichos servicios, compartiremos los
            nombres y demás información de contacto que resulte necesaria para que
            los terceros puedan brindar los mismos. No se permitirá a dichos
            terceros el uso de información de identificación personal excepto que
            ello fuera necesario para brindar los referidos servicios.
          </p>
        </li>

        <li>
          <p>
            Podemos asimismo compartir Información Personal respecto de los
            visitantes a nuestro sitio web entre sus afiliadas y subsidiarias para
            fines vinculados a las actividades comerciales de dichas afiliadas y
            subsidiarias.
          </p>
        </li>
      </ul>

      <p>
        Syngenta podrá también compartir Información Personal con terceros
        relacionada con los visitantes de sitios web, en los siguientes casos:
      </p>
      <ul>
        <li>
          <p>
            Cuando se hubiera prestado consentimiento respecto de la divulgación
            de dicha información;
          </p>
        </li>

        <li>
          <p>
            Para dar cumplimiento a leyes, resoluciones judiciales, citaciones o
            procesos legítimos;
          </p>
        </li>

        <li>
          <p>
            Para hacer cumplir o aplicar los acuerdos de Syngenta o brindar
            protección a Syngenta y a sus derechos,
          </p>
        </li>

        <li>
          <p>
            De pleno derecho, cuando dicha Información Personal sea transferida
            como parte de la venta de activos incluyendo los activos representados
            por nuestro Portal; o
          </p>
        </li>

        <li>
          <p>
            En el supuesto que Syngenta, o una parte significativa de sus activos,
            fueran adquiridos fuera del giro habitual de los negocios, y dicha
            Información Personal formara parte de los activos transferidos.
          </p>
        </li>
      </ul>

      <p>
        <strong>ELIMINACIÓN DE INFORMACIÓN PERSONAL </strong>
      </p>
      <p>
        SYNGENTA destruirá en forma periódica la Información Personal recopilada
        de nuestros visitantes en el Portal en caso de que la misma no fuera
        necesaria para ningún fin para el cual pudiera utilizarse o proporcionarse
        dicha información de conformidad con los términos de esta Política.
      </p>
      <p>
        <strong>SEGURIDAD </strong>
      </p>
      <p>
        Toda información recabada en el Portal se tratará en cumplimiento de la
        Normativa y los datos personales se utilizarán únicamente para los fines
        aquí consentidos o los que consienta oportunamente. Syngenta expresa su
        compromiso de proteger la seguridad de la información personal de los
        usuarios. Con ese fin, Syngenta usa una amplia variedad de tecnologías y
        procedimientos de seguridad para proteger la información personal contra
        un acceso, uso, modificación o una divulgación no autorizados. Sin
        perjuicio de esto, le recomendamos que no incluya información
        confidencial, secreta, comprometedora, datos sensibles o información
        personal delicada que usted no desea revelar en estas comunicaciones
        (incluyendo correos electrónicos). Asimismo, usted es responsable de
        proteger la confidencialidad de saus credenciales de acceso a la
        aplicación.
      </p>
      <p>
        Usted reconoce y acepta que, a pesar de todos nuestros esfuerzos, ninguna
        medida de seguridad de datos puede garantizar por completo la protección
        en todo momento.
      </p>
      <p>
        <strong>NIÑOS</strong>
      </p>
      <p>
        Este sitio no está dirigido a niños. No deseamos recopilar ninguna
        información que identifique personalmente a menores de edad (menor de 18
        años). Si un menor de edad nos hubiera suministrado información que lo
        identifica personalmente, sus padres y/o tutores deberán comunicarse con
        nosotros a través del contacto indicado debajo. En caso de tomar
        conocimiento que los datos de un menor de edad se han proporcionado a
        Syngenta, eliminaremos dicha información a la mayor brevedad posible
      </p>
      <p>
        <strong>CAMBIOS EN LA POLÍTICA </strong>
      </p>
      <p>
        Si Syngenta decide modificar sus políticas y prácticas de privacidad,
        daremos a conocer dichos cambios en nuestra Política de Privacidad en
        nuestro sitio web antes que los mismos cobren vigencia de modo que
        nuestros usuarios tengan conocimiento en todo momento de la información
        que recopilamos, cómo la utilizamos, y bajo qué circunstancias, si las
        hubiera, la revelamos. Si en algún momento decidimos utilizar información
        de identificación personal en una forma diferente a la indicada en el
        momento en que fuera recolectada, cursaremos notificación de ello a los
        usuarios. Los usuarios tendrán la opción de que usemos o no su información
        en esta forma. Continuaremos usando la información de conformidad con la
        Política de Privacidad en virtud de la cual se ha recolectado la
        información.
      </p>
      <p>
        <strong>CONTACTO CON SYNGENTA</strong>
      </p>
      <p>
        El Portal brinda una sección “CONTACTO” para facilitar la respuesta de los
        visitantes del sitio. Sírvase utilizar este medio para notificarnos sus
        consultas, preocupaciones o reclamos respecto de la forma en que su
        información personal ha sido recopilada o manejada por SYNGENTA o bien,
        diríjase por escrito con acreditación fehaciente de su identidad a:
      </p>
      <p>Por correo electrónico a: [_________]</p>
      <p>
        <strong>JURISDICCIÓN Y LEY APLICABLE</strong>
      </p>
      <p>
        La presente Política se encuentra regida sin excepción y en todos sus
        términos por las leyes de la República de Argentina y se interpretará de
        acuerdo con ellas. Cualquier controversia derivada de la presente
        Política, su existencia, validez, interpretación, alcance o cumplimiento
        se someterá a los tribunales ordinarios de Justicia con jurisdicción en la
        Ciudad Autónoma de Buenos Aires, renunciándose en forma expresa a
        cualquier otro fuero o jurisdicción.
      </p>
      <p>
        <strong>CONSENTIMIENTO</strong>
      </p>
      <p>
        Presto mi consentimiento para que mis datos personales, sean tratados en
        Argentina, y cedidos en caso de ser necesario para su procesamiento en la
        Unión Europea, Uruguay o Estados Unidos de conformidad con la legislación
        vigente en Argentina. Asimismo, presto mi consentimiento para que me hagan
        llegar vía correo electrónico u otro medio de comunicación, información
        sobre promociones y novedades relacionadas con productos y servicios de
        SYNGENTA, decisión que puedo revocar personalmente en todo momento a
        través de la configuración personal de mi cuenta.
      </p>
      <p>
        <strong>
          ACCESO DEL TITULAR DE LOS DATOS PERSONALES -DISPOSICIÓN 10/2008 – DNPDP
        </strong>
      </p>
      <p>
        El titular de los datos personales tiene la facultad de ejercer el derecho
        de acceso a los mismos en forma gratuita a intervalos no inferiores a seis
        meses, salvo que se acredite un interés legítimo al efecto conforme lo
        establecido en el artículo 14, inciso 3 de la Ley N.º 25.326.
        <br />
        La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de Control
        de la Ley N.º 25.326, tiene la atribución de atender las denuncias y
        reclamos que se interpongan con relación al incumplimiento de las normas
        sobre protección de datos personales
      </p>
    </Page>
  );
}
