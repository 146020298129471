import { useContext } from "react";
import { AppContext } from 'context/AppContext';

export default function useAppContext() {
    const context = useContext(AppContext);

    if (context === undefined) {
      throw new Error('useAppContext was used outside of its Provider')
    }

    return context;
}
