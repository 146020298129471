import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import { ReactComponent as SendIcon } from 'assets/send.svg';

const MAX_LENGTH = 100;

export default function InputMensaje({ tipo }) {
  const axios = useAxios();
  const inputRef = useRef(null);
  const { entregaId } = useParams();
  const { setMensajes } = useAppContext();
  const [texto, setTexto] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleChange = (event) => {
    setTexto(event.target.value);
  };

  const handleClick = async () => {
    setIsSending(true);

    try {
      await axios.post(`/distribuidor/entregas/${entregaId}/mensajes`, {
        texto,
        tipo,
      });

      const response = await axios.get(
        `/distribuidor/entregas/${entregaId}/mensajes`,
      );

      setMensajes(response.data);
      setTexto('');
      inputRef.current.focus();
    } catch (error) {
      console.error(error)
    }

    setIsSending(false);
  };

  return (
    <div className="flex flex-col gap-2">
      {texto.length >= MAX_LENGTH && (
        <div className="text-rose-500 text-sm">
          No puede exceder los 100 caracteres
        </div>
      )}

      <div className="flex gap-2">
        <textarea
          className={`w-full rounded-md focus:ring-0 border disabled:opacity-50 ${texto.length >= MAX_LENGTH ? 'border-rose-500 focus:border-rose-500' : 'border-transparent'}`}
          placeholder="Ingrese su mensaje"
          required
          onChange={handleChange}
          value={texto}
          autoFocus
          ref={inputRef}
          disabled={isSending}
        ></textarea>

        <button
          type="button"
          className="ec__btn--primary py-2 px-4 rounded-md disabled:bg-gray-300"
          onClick={handleClick}
          disabled={isSending || texto.length === 0 || texto.length >= 100}
        >
          <SendIcon />
        </button>
      </div>
    </div>
  );
};
