import { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import FilterAccordion from './FilterAccordion';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { ReactComponent as SlidersIcon } from 'assets/sliders.svg';

export default function Filter({
  filters,
  initialSelectedOptions,
  onApply,
  onClean,
}) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const resetSelectedOptions = () => {
    setSelectedOptions({...initialSelectedOptions});
  };

  const handleApply = () => {
    onApply && onApply({...selectedOptions});
    setShowModal(false);
  };

  const handleClean = () => {
    onClean && onClean();
    setShowModal(false);
  };

  const handleClose = () => {
    resetSelectedOptions();
    setShowModal(false);
  };

  useEffect(() => {
    resetSelectedOptions();
  }, [initialSelectedOptions])

  return (
    <>
      {/* Mobile */}
      <button
        type="button"
        className="ec__btn--primary flex items-center justify-center p-4 text-white rounded-lg w-full lg:hidden"
        onClick={() => {
          setActiveFilters(Object.keys(filters).map((key) => key));
          setShowModal(true);
        }}
      >
        <FilterIcon className="mr-1" />
        Filtros
      </button>

      {/* Desktop */}
      <div className="hidden lg:flex py-2 px-4 border border-[#d1d1d1] rounded-md">
        <div
          className="flex items-center pr-4 mr-4 border-r"
          style={{ color: '#909191' }}
        >
          Filtros <SlidersIcon className="ml-1" />
        </div>
        <div className="flex">
          {Object.keys(filters).map((key) => (
            <button
              key={key}
              type="button"
              className={`ec__text--primary flex items-center px-2 py-1 rounded-md mr-2 ${selectedOptions[key]?.length > 0 && 'ec__btn--secondary'}`}
              onClick={() => {
                setActiveFilters([key]);
                setShowModal(true);
              }}
            >
              <FilterIcon className="mr-1" /> {filters[key].title}
            </button>
          ))}
        </div>
      </div>

      {/* Filters */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header onClose={handleClose}>
          Filtros
        </Modal.Header>
        <Modal.Body>
          {activeFilters.map((key) => (
            <FilterAccordion
              key={key}
              filterKey={key}
              filter={filters[key]}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="ec__btn--primary py-2 rounded-md border-2 font-medium border-transparent lg:w-1/2"
            type="button"
            onClick={handleApply}
          >
            Aplicar
          </button>

          <button
            className="ec__text--primary py-2 font-medium lg:w-1/2 hidden lg:block"
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>

          <button
            type="button"
            className="lg:hidden flex py-2 items-center justify-center gap-2 text-red-500 outline outline-red-500 rounded-md font-medium text-white"
            onClick={handleClean}
          >
            <CloseIcon /> Limpiar filtros
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
