import styled from 'styled-components';

const StyledItem = styled.p`
  background-color: #fff;
  height: 60px;
  margin-bottom: 2px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 400;
  font-size: 16px;

  strong {
    margin-right: 4px;
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const Item = ({ data }) => (
  <div>
    <StyledItem>
      <strong>Distribuidor: </strong>
      {data.distribuidor.nombre}
    </StyledItem>
    <StyledItem>
      <strong>Destinatario: </strong>
      {data.destinatario.nombre}
    </StyledItem>
    <StyledItem>
      <strong>Dirección: </strong>
      {`${data.destinatario.direccion}, ${data.destinatario.localidad.nombre}`}
    </StyledItem>
    <StyledItem>
      <strong>Cliente: </strong>
      {data.cliente.nombre}
    </StyledItem>
    <StyledItem>
      <strong>N° de Remito: </strong>
      {data.numero_remito || '--'}
    </StyledItem>
    <StyledItem>
      <strong>N° de Pedido: </strong>
      {data.numero_pedido || '--'}
    </StyledItem>
    <StyledItem>
      <strong>Fecha de desp.: </strong>
      {data.fecha_despacho || '--'}
    </StyledItem>
  </div>
);
export default Item;
