import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import InputMensaje from 'components/Distribuidor/InputMensaje';
import Mensaje from 'components/Distribuidor/Mensaje';
import Page from 'components/Distribuidor/Mobile/Page';
import useAppContext from 'hooks/useAppContext';

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView({behavior: 'smooth'}));
  return <div ref={elementRef} />;
};

export default function Mensajes() {
  const location = useLocation();
  const { entrega, mensajes } = useAppContext();

  let tipo = location.state?.tipo || mensajes[0]?.tipo || 'other';

  return (
    <Page title={`N° Transporte ${entrega ? entrega.transporte.codigo : '...'}`}>
      <div className="flex flex-col" style={{ height: 'calc(100vh - 160px)' }}>
        <div className="grow overflow-y-auto">
          {mensajes.map((mensaje, index) => (
            <Mensaje key={index} data={mensaje} />
          ))}
          <AlwaysScrollToBottom />
        </div>
        <InputMensaje tipo={tipo} />
      </div>
    </Page>
  );
};
