import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReceptorForm from 'components/ReceptorForm';
import Page from 'components/Page';
import useAxios from 'hooks/useAxios';

export default function Receptor() {
  const axios = useAxios();
  const history = useHistory();
  const { receptorId } = useParams();
  const [receptor, setReceptor] = useState(null);
  const [destinatarios, setDestinatarios] = useState([]);

  const handleCancel = () => {
    history.push(`/receptores`);
  }

  const handleCreate = async (body, errorHandler) => {
    try {
      const response = await axios.post(
        '/distribuidor/receptores',
        body,
      );

      handleCancel();
    } catch (error) {
      errorHandler(error.response.data?.errors ?? {});
    }
  };

  const handleUpdate = async (body, errorHandler) => {
    try {
      const response = await axios.put(
        `/distribuidor/receptores/${receptorId}`,
        body,
      );

      handleCancel();
    } catch (error) {
      errorHandler(error.response.data?.errors ?? {});
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/distribuidor/receptores/${receptorId}`);

      handleCancel();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReceptor = async () => {
    try {
      const response = await axios.get(`/distribuidor/receptores/${receptorId}`);
      setReceptor(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDestinatarios = async () => {
    try {
      const response = await axios.get('/distribuidor/destinatarios');

      setDestinatarios(response.data);

      if (receptorId) {
        await fetchReceptor();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDestinatarios();
    if (receptorId) {
      fetchReceptor();
    }
  }, []);

  return (
    <Page>
      <Page.Header>
        <Page.Title>
          {receptor
            ? `Modificar receptor: ${receptor.nombre} ${receptor.apellido}`
            : 'Crear nuevo receptor'
          }
        </Page.Title>
      </Page.Header>

      <ReceptorForm
        receptor={receptor}
        destinatarios={destinatarios}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={handleCancel}
        redirectTo="/receptores"
      />
    </Page>
  );
};
