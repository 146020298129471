const EstadoEntrega = ({ estado }) => {
  const estados = {
    encurso: {
      text: 'En curso',
      color: '#124dbf',
    },
    pendiente: {
      text: 'Pendiente',
      color: '#4c4c4c',
    },
    entregado: {
      text: 'Entregado',
      color: '#333',
    },
    rechazado: {
      text: 'Rechazado',
      color: '#ff5722',
    },
  };

  return (
    <span
      className="inline-block py-1 rounded-md text-white text-sm font-bold"
      style={{
        backgroundColor: estados[estado].color,
        width: '120px',
      }}
    >
      {estados[estado].text}
    </span>
  );
};

export default EstadoEntrega;
