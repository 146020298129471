import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/Distribuidor/Mobile/Page';
import useAxios from 'hooks/useAxios';

export default function Home() {
  const axios = useAxios();
  const [transportes, setTransportes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/distribuidor/transportes');
      setTransportes(response.data.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Envíos">
      {!isLoading && transportes.length === 0 && (
        <div className="flex flex-col items-center w-full">
          <div className="bg-gray-300 w-48 h-48 mb-3" />
          <div className="text-xl text-center">
            Aún no tienes pedidos pendientes o en curso
          </div>
        </div>
      )}

      {!isLoading && transportes.map((transporte) => (
        <div
          key={transporte.codigo}
          className="flex flex-col items-start p-4 bg-white rounded-lg mb-3 w-full"
        >
          <div className="mb-3">
            <div className="text-gray-800 text-sm">
              {transporte.estado === 'encurso' ? 'En Curso' : 'Despachado'}
            </div>
            <div className="text-sm text-green-600">
              {`${transporte.cantidad_destinatarios} ${
                transporte.cantidad_destinatarios === 1 ? 'DESTINATARIO' : 'DESTINATARIOS'
              }`}
              {' - '}
              {`${transporte.entregas.length} ${
                transporte.entregas.length === 1 ? 'ENTREGA' : 'ENTREGAS'
              }`}
            </div>
            <div className="text-black-600 text-xl font-medium">
              {`TRANSPORTE ${transporte.codigo}`}
            </div>
          </div>
          <Link
            to={{
              pathname: `/transporte`,
              state: { transporte },
            }}
            className="ec__btn--primary py-2 px-4 rounded-md"
          >
            Ver más
          </Link>
        </div>
      ))}
    </Page>
  );
};
