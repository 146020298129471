import { Switch, Route } from 'react-router-dom';
import Notificaciones from 'pages/Distribuidor/Mobile/Notificaciones';
import Articulos from 'pages/Distribuidor/Mobile/Articulos';
import Mensajes from 'pages/Distribuidor/Mobile/Mensajes';
import DetalleEntrega from 'pages/Distribuidor/Mobile/DetalleEntrega';
import Entregas from 'pages/Distribuidor/Mobile/Entregas';
import Transporte from 'pages/Distribuidor/Mobile/Transporte';
import Home from 'pages/Distribuidor/Mobile/Home';
import NotFound from 'components/NotFound';

export default function RoutesDistribuidorMobile() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/transporte" component={Transporte} />
      <Route path="/entregas" exact component={Entregas} />
      <Route path="/entregas/:entregaId" component={DetalleEntrega} />
      <Route path="/entregas-mensajes/:entregaId" component={Mensajes} />
      <Route path="/entregas-articulos/:entregaId" component={Articulos} />
      <Route path="/notificaciones" component={Notificaciones} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
